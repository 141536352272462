import { Capability } from "../Capability"

const sortByPreset = <E extends { action: string; target: string }>(arr: E[], presets: string[]) =>
  arr.sort((a, b) => {
    const aIndex = presets.indexOf(a.action)
    const bIndex = presets.indexOf(b.action)
    return aIndex - bIndex
  })

export const byPreset = (preset: string[], capabilities: Capability[]) =>
  sortByPreset(
    capabilities.filter(({ action }) => preset.includes(action)),
    preset,
  )
