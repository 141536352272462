import { ComponentType, ReactNode } from "react"
import styled from "styled-components"
import { forEnum } from "../../utils/enum"
import { forwardRef } from "react"
import { Spinner } from "@hero/krypton"

type ButtonSize = "small" | "medium" | "large" | "xlarge"
type ButtonVariant = "primary" | "secondary" | "tertiary"

type ButtonPropsBase = {
  LeftIcon?: ComponentType
  RightIcon?: ComponentType
  onClick?: (e: React.MouseEvent) => void
  children: ReactNode
  type?: "button" | "submit" | "reset"
}

type ButtonPropsOtherVariant = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonPropsBase & {
    $variant?: ButtonVariant
    size?: ButtonSize
    isLoading?: boolean
    $fullWidth?: boolean
    $plain?: boolean
  }

type ButtonProps = ButtonPropsOtherVariant

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { $variant, $plain, disabled, LeftIcon, onClick, RightIcon, size, children, type, $fullWidth, isLoading, style },
    ref,
  ) => {
    let Btn = PrimaryButton

    if ($variant === "secondary") {
      Btn = SecondaryButton
    }

    if ($variant === "tertiary") {
      Btn = TertiaryButton
    }

    if (disabled) {
      Btn = DisabledButton
    }

    const Typo = getButtonTypography(size || "medium", $variant || "primary")

    return (
      <Btn
        ref={ref}
        size={size || "medium"}
        $fullWidth={$fullWidth}
        $plain={$plain}
        onClick={onClick}
        disabled={disabled}
        type={type || "button"}
        style={style}
      >
        {!isLoading && (
          <>
            {LeftIcon && <LeftIcon />}
            <Typo>{children}</Typo>
            {RightIcon && <RightIcon />}
          </>
        )}

        {isLoading && <Spinner />}
      </Btn>
    )
  },
)

const getButtonHeight = (size: ButtonSize) => {
  return forEnum(size, {
    small: () => "2rem",
    medium: () => "2.5rem",
    large: () => "3rem",
    xlarge: () => "3.5rem",
  })
}

const getButtonPadding = (size: ButtonSize) => {
  return forEnum(size, {
    small: () => "0 0.75rem",
    medium: () => "0 0.75rem",
    large: () => "0 0.75rem",
    xlarge: () => "0 1rem",
  })
}

const getButtonTypography = (size: ButtonSize, variant: ButtonVariant) => {
  const typo = forEnum(size, {
    small: () => smallButtonTypography,
    medium: () => mediumButtonTypography,
    large: () => largeButtonTypography,
    xlarge: () => xlargButtonTypography,
  })

  return typo
}

const getBorder = (color: string, $plain?: boolean) => {
  if (!$plain) {
    return `1px solid ${color}`
  }

  return "none"
}

const smallButtonTypography = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;
  text-align: center;
`

const mediumButtonTypography = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
`
const largeButtonTypography = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
`
const xlargButtonTypography = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
`

const DISABLED_TEXT_COLOR = "#919196"
const DISABLED_BG_COLOR = "#F0F0F5"

interface StyledButtonProps {
  size: ButtonSize
  $fullWidth?: boolean
  $plain?: boolean
}

const ButtonBase = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  gap: 0.25rem;
  cursor: pointer;
  padding: ${({ size }) => getButtonPadding(size)};
  height: ${({ size }) => getButtonHeight(size)};
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};

  & svg,
  & img {
    height: 1rem;
    width: 1rem;
  }
`

const DisabledButton = styled(ButtonBase)<StyledButtonProps>`
  cursor: not-allowed;
  background-color: ${DISABLED_BG_COLOR};

  & span,
  & svg {
    color: ${DISABLED_TEXT_COLOR};
  }
`

const PrimaryButton = styled(ButtonBase)<StyledButtonProps>`
  background-color: ${({ theme }) => theme.colors.grey.$600};

  & span,
  & svg {
    color: white;
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.grey.$400};
  }
`

const SecondaryButton = styled(ButtonBase)<StyledButtonProps>`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ $plain }) => ($plain ? "0" : "0px 1px 2px 0px #0000000a")};
  border: ${({ theme, $plain }) => getBorder(theme.colors.grey.$200, $plain)};

  & span,
  & svg {
    color: ${({ theme }) => theme.colors.grey.$500};
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.secondary.$100};
  }
`

const MAJORELLE_BLUE = "#624AFF"

const TertiaryButton = styled(ButtonBase)<StyledButtonProps>`
  background-color: ${MAJORELLE_BLUE};

  & span,
  & svg {
    color: ${({ theme }) => theme.colors.white};
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.secondary.$100};
  }
`
