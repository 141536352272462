import { Typography } from "@hero/krypton"
import styled from "styled-components"

export const DashboardSection = styled.section<{ $isSticky?: boolean }>`
  padding: 1rem;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
  border-radius: 0.75rem;
  position: ${({ $isSticky: isSticky }) => (isSticky ? "sticky" : "static")};
  top: 0rem;

  @media (max-width: 768px) {
    border-radius: 0;
    border: none;
    padding: 2rem 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }
`

export const DashboardSectionHeader = styled.div``

export const DashboardSectionHeaderIconWrapper = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #00000030;

  // if text
  font-size: 10px;
  font-weight: 900;
  font-style: italic;
  color: ${({ theme }) => theme.colors.secondary.$200};

  // if icon
  & > svg {
    height: 1rem;
    width: 1rem;
  }
`

export const DashboardSectionTitle = styled(Typography).attrs({
  $variant: "body-3-medium",
})`
  display: flex;
  justify-items: center;
  gap: 0.5rem;
`
