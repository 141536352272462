import { AwsRum, AwsRumConfig } from "aws-rum-web"

export const initAwdRum = () => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: "eu-west-3:0959211b-db53-43aa-9f97-1a7378a1ba9b",
      endpoint: "https://dataplane.rum.eu-west-3.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: true,
    }

    const APPLICATION_ID = "7c3db61f-df4b-4d72-bce2-1458c4c6d5f0"
    const APPLICATION_VERSION = "1.0.0"
    const APPLICATION_REGION = "eu-west-3"

    const awsRum: AwsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
