import Intercom, { shutdown, update } from "@intercom/messenger-js-sdk"
import { createContext, useContext, useEffect } from "react"
import { useAuthContext } from "./Auth/auth.context"
import { ADMIN_URI, INTERCOM_APP_ID, INTERCOM_ENABLED } from "./env_variables"

interface IntercomContextInterface {
  bootIntercom: () => void
}

const defaultFunction = () => {
  // should not happen
  console.error("ERROR_DEFAULT_CONTEXT_VALUE_USED")
}

const IntercomContext = createContext<IntercomContextInterface>({
  bootIntercom: defaultFunction,
})

export const useIntercomContext = () => useContext(IntercomContext)

interface IntercomProviderProps {
  children: JSX.Element | JSX.Element[]
}

const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const { currentUser } = useAuthContext()

  useEffect(() => {
    if (currentUser && INTERCOM_ENABLED) {
      shutdown()
      update({
        app_id: INTERCOM_APP_ID,
        user_hash: currentUser.intercomUserHash,
        user_id: currentUser.id,
        name: `${currentUser.firstname} ${currentUser.lastname}`,
        email: currentUser.email,
        phone: currentUser.phone,
        UserRole: currentUser.role,
        company: {
          id: currentUser.merchantId,
          name: currentUser.merchantTradingName,
          company_address_1: currentUser.address?.line1,
          company_address_2: currentUser.address?.line2,
          company_city: currentUser.address?.city,
          company_region: currentUser.address?.region,
          company_zipCode: currentUser.address?.zipCode,
          company_countryCode: currentUser.address?.countryCode,
          bo_url: `${ADMIN_URI}/admin/merchants/${currentUser.merchantId}`,
        },
        boot_on_load: true,
        autoBoot: true,
      })
    }
    if (!currentUser) {
      bootIntercom()
    }
  }, [currentUser])

  const bootIntercom = () => {
    if (INTERCOM_ENABLED) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        region: "eu",
        boot_on_load: true,
        autoBoot: true,
      })
    }
  }

  return <IntercomContext.Provider value={{ bootIntercom }}>{children}</IntercomContext.Provider>
}

export default IntercomProvider
