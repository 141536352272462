export const exportCsv = (args: { data: Record<string, any>[]; fileName: string }) => {
  const csvHeaders = Object.keys(args.data[0]).join(",")
  const csvValues = args.data.map((row) => Object.values(row).join(",")).join("\n")

  const csv = `${csvHeaders}\n${csvValues}`

  return saveTextFile({
    content: csv,
    fileName: args.fileName + ".csv",
  })
}

const saveTextFile = (args: { content: string; fileName: string }) => {
  const blob = new Blob([args.content], { type: "text/csv" })
  const url = URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = args.fileName
  a.click()

  URL.revokeObjectURL(url)
}
