import { Separator, Typography } from "@hero/krypton"
import { useCallback, useState } from "react"
import { useLocation, useNavigate, useNavigationType } from "react-router-dom"
import styled from "styled-components"
import { FlexContainer, FlexItem } from "../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../../00_shared/components/Sheet"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { getProductTypeFromFlow, LedgerMovementFlow, OperationType, ProductType } from "../../00_shared/business"
import { TransactionNote } from "../../00_shared/components/TransactionNote"
import { TransactionReceipt } from "../../00_shared/components/TransactionReceipt"
import { isNegative } from "../../00_shared/utils"
import { DisputeIssuingTransaction } from "./components/DisputeIssuingTransaction"
import { TransactionDetails } from "./components/transactions/TransactionInformations/TransactionDetails"
import { TransactionSteps } from "./components/transactions/TransactionInformations/TransactionSteps"
import { useUnifiedOperationDetails } from "./hooks/transactionDetails/useUnifiedOperationDetails"

const ScrollAreaContainer = styled(ScrollArea)`
  padding-right: 0.5rem;
`

const Amount = styled(Typography).attrs(() => ({
  $variant: "title-3-semibold",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const Date = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

interface TransactionInformationProps {
  flow: LedgerMovementFlow
  ledgerMovementId: string
  transactionId: string
  ledgerId: string
}

export const AccountTransactionInformations = () => {
  const { t: td } = useDashboardTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const { state } = useLocation() as { state: TransactionInformationProps }
  const { formattedOperationDetails, productSpecific, loading } = useUnifiedOperationDetails({
    ledgerMovementId: state.ledgerMovementId,
    flow: state.flow,
    operationId: state.transactionId,
    businessAccountId: state.ledgerId,
  })
  const productType = getProductTypeFromFlow(state.flow)

  const transactionDetailsNotFound = formattedOperationDetails === undefined && !loading

  const handleAnimationEnd = () => {
    navigateBack()
  }

  const navigateBack = useCallback(() => {
    if (!isOpen || transactionDetailsNotFound) {
      if (navigationType === "PUSH") {
        navigate(-1)
      } else {
        navigate("..")
      }
    }
  }, [isOpen, transactionDetailsNotFound, navigationType, navigate])

  if (!formattedOperationDetails) {
    navigateBack()
    return
  }

  const displayTransactionStep =
    isNegative(formattedOperationDetails.amount) &&
    formattedOperationDetails.operationType !== OperationType.FEES_OUT &&
    formattedOperationDetails.operationType !== OperationType.ACCOUNT_TO_ACCOUNT_OUT

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side="right" onAnimationEndCapture={handleAnimationEnd}>
        <SheetHeader>
          <SheetTitle>{formattedOperationDetails.pageTitle}</SheetTitle>
        </SheetHeader>
        <ScrollAreaContainer height="calc(100vh - 6rem)" width="100%">
          <FlexContainer $direction="column" $gap="1.2rem">
            <FlexItem>
              <FlexContainer $justify="space-between" $align="center">
                <Typography $variant="title-3-semibold">{formattedOperationDetails.label}</Typography>
                <Amount>{formattedOperationDetails.amount}</Amount>
              </FlexContainer>
              <Date>{formattedOperationDetails.createdAt}</Date>
            </FlexItem>
            <Separator />
            {formattedOperationDetails.status && displayTransactionStep && (
              <FlexItem>
                <TransactionSteps statuses={formattedOperationDetails.status} />
              </FlexItem>
            )}
            {formattedOperationDetails.operationItems.length > 0 && (
              <FlexItem>
                <TransactionDetails items={formattedOperationDetails.operationItems} />
              </FlexItem>
            )}
            {productType === ProductType.BUSINESS_ACCOUNT && (
              <>
                <Separator />
                <FlexItem>
                  <TransactionReceipt
                    title={td("accounts.transactionInformations.receipt.title")}
                    operationId={state.transactionId}
                    fileUrl={formattedOperationDetails.justificativePath}
                    ledgerMovementId={state.ledgerMovementId}
                    flow={state.flow}
                    ledgerId={state.ledgerId}
                  />
                </FlexItem>
              </>
            )}
            {productType === ProductType.BUSINESS_ACCOUNT && (
              <>
                <Separator />
                <FlexItem>
                  <TransactionNote
                    title={td("accounts.transactionInformations.note.title")}
                    operationId={state.transactionId}
                    ledgerMovementId={state.ledgerMovementId}
                    flow={state.flow}
                    note={formattedOperationDetails.note}
                    ledgerId={state.ledgerId}
                  />
                </FlexItem>
              </>
            )}
            {productType === ProductType.ISSUING && productSpecific?.ISSUING.transactionId && (
              <DisputeIssuingTransaction
                transactionId={productSpecific.ISSUING.transactionId}
                label={formattedOperationDetails.label}
                date={formattedOperationDetails.createdAt}
                amount={formattedOperationDetails.amount}
              />
            )}
          </FlexContainer>
        </ScrollAreaContainer>
      </SheetContent>
    </Sheet>
  )
}
