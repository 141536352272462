import { theme } from "@hero/krypton"
import styled from "styled-components"
import { useMenuLayoutContext } from "../../Merchant/00_shared/components/MenuLayoutContext"

const Hamburger = styled.div<{ open: boolean }>`
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  div {
    width: 100%;
    height: 2px;
    background-color: ${({ open }) => (open ? "#000" : theme.colors.grey.$600)};
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
  div:nth-child(1) {
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    transform-origin: top left;
  }
  div:nth-child(2) {
    opacity: ${({ open }) => (open ? "0" : "1")};
  }
  div:nth-child(3) {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    transform-origin: bottom left;
  }
`

export const HamburgerMenu = () => {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useMenuLayoutContext()

  return (
    <Hamburger open={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen((prev) => !prev)}>
      <div />
      <div />
      <div />
    </Hamburger>
  )
}
