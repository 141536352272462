import { ChevronDownIcon, Typography, ValidIcon } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { useCommonTranslation } from "../../../01_technical/translations"

export const Timeline = ({
  timeline,
}: {
  timeline: {
    title: string
    subtitle?: string
    isDone?: boolean
  }[]
}) => {
  const { t } = useCommonTranslation()

  const [isHistoryDetailsDisplayed, setIsHistoryDetailsDisplayed] = useState(false)

  const timelineDetails = timeline.slice(1, -1)
  const estimatedElementHeight = 2.625
  const estimatedGapHeight = 1.5
  const estimatedPaddingHeight = 4
  const estimatedHistoryDetailsHeight =
    estimatedElementHeight * timelineDetails.length +
    estimatedGapHeight * (timelineDetails.length - 1) +
    estimatedPaddingHeight +
    "rem"

  return (
    <HistorySection>
      <HistoryPoint>
        <HistoryIcon isDone={timeline[0].isDone !== false} />
        <HistoryTextContent>
          <HistoryTitle>{timeline[0].title}</HistoryTitle>
          <HistorySubtitle>{timeline[0].subtitle}</HistorySubtitle>
        </HistoryTextContent>
      </HistoryPoint>

      <HistoryDetailsLineRelative>
        <HistoryToggleDetailsWrapper
          type="button"
          onClick={() => {
            setIsHistoryDetailsDisplayed(!isHistoryDetailsDisplayed)
          }}
        >
          <HistoryToggleDetailsIcon isHistoryDetailsDisplayed={isHistoryDetailsDisplayed} />
          <HistoryToggleDetailsLabel>{t("timeline.details")}</HistoryToggleDetailsLabel>
        </HistoryToggleDetailsWrapper>

        <HistoryDetailsLine />
        <HistoryDetailsWrapper
          isHistoryDetailsDisplayed={isHistoryDetailsDisplayed}
          estimatedHeight={estimatedHistoryDetailsHeight}
        >
          {timelineDetails.map((detail) => (
            <HistoryPoint>
              <HistoryPointIcon />
              <HistoryTextContent>
                <HistoryTitle>{detail.title}</HistoryTitle>
                <HistorySubtitle>{detail.subtitle}</HistorySubtitle>
              </HistoryTextContent>
            </HistoryPoint>
          ))}
        </HistoryDetailsWrapper>
      </HistoryDetailsLineRelative>

      <HistoryPoint>
        <HistoryIcon isDone={timeline[timeline.length - 1].isDone !== false} />
        <HistoryTextContent>
          <HistoryTitle>{timeline[timeline.length - 1].title}</HistoryTitle>
          <HistorySubtitle>{timeline[timeline.length - 1].subtitle}</HistorySubtitle>
        </HistoryTextContent>
      </HistoryPoint>
    </HistorySection>
  )
}

const HistoryTitle = styled(Typography).attrs({ $variant: "body-4-regular" })`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const HistorySubtitle = styled(Typography).attrs({ $variant: "caption-2" })`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const HistoryDetailsWrapper = styled.div<{ isHistoryDetailsDisplayed: boolean; estimatedHeight: string }>`
  max-height: ${({ isHistoryDetailsDisplayed, estimatedHeight }) =>
    isHistoryDetailsDisplayed ? estimatedHeight : "0"};

  transition:
    max-height 0.25s,
    padding 0.3s;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: ${({ isHistoryDetailsDisplayed }) => (isHistoryDetailsDisplayed ? "2rem 0rem" : "0")};
  position: relative;
`

const HistoryDetailsLineRelative = styled.div`
  position: relative;
`

const HistoryDetailsLine = styled.div`
  position: absolute;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.grey.$300};
  top: 0;
  bottom: 0;
  left: 13px;
  border-radius: 2px;
`

const HistorySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const HistoryPoint = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const HistoryPointIcon = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${({ theme }) => theme.colors.grey.$300};
  border-radius: 100%;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`

const HistoryTextContent = styled.div`
  flex: 1;
  overflow: hidden;
`

const HistoryIcon = styled(ValidIcon)<{ isDone: boolean }>`
  height: 1.75rem;
  width: 1.75rem;
  background-color: ${({ theme, isDone }) => (isDone ? theme.colors.primary.$200 : theme.colors.grey.$200)};
  color: white;
  border-radius: 100%;
  padding: 0.2rem;
`

const HistoryToggleDetailsWrapper = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: 2.25rem;
`

const HistoryToggleDetailsIcon = styled(ChevronDownIcon)<{ isHistoryDetailsDisplayed: boolean }>`
  transform: ${({ isHistoryDetailsDisplayed }) => (isHistoryDetailsDisplayed ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.25s;
  height: 1.5rem;
  width: 1.5rem;
`

const HistoryToggleDetailsLabel = styled(Typography).attrs({ $variant: "caption-1" })``
