import { Badge } from "../../../00_shared/components/krypton2/Badge"
import { forEnumOrElse } from "../../../00_shared/utils/enum"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { User } from "../00_shared/User"

export const UserStatusBadge = ({ status, hasPendingInvitation }: Pick<User, "status" | "hasPendingInvitation">) => {
  const { t } = useDashboardTranslation()

  return forEnumOrElse(
    status,
    {
      ACTIVE: () => {
        if (hasPendingInvitation) {
          return <Badge $variant="info">{t("userManagement.userStatus.invited")}</Badge>
        }
        return <Badge $variant="primary">{t("userManagement.userStatus.active")}</Badge>
      },
      INACTIVE: () => {
        if (hasPendingInvitation) {
          return <Badge $variant="info">{t("userManagement.userStatus.invited")}</Badge>
        }
        return <Badge $variant="disabled">{t("userManagement.userStatus.inactive")}</Badge>
      },
      DELETED: () => {
        return <Badge $variant="disabled">{t("userManagement.userStatus.deleted")}</Badge>
      },
      SUSPENDED: () => {
        return <Badge $variant="danger">{t("userManagement.userStatus.suspended")}</Badge>
      },
    },
    // eslint-disable-next-line i18next/no-literal-string
    () => <div>unknown status</div>,
  )
}
