import { Button, Header, Typography } from "@hero/krypton"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useAmplitude } from "../../../../../00_shared/hooks/useAmplitude.hook"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import { TransfersHeaderTabs } from "./TransfersHeaderTabs"

const HeaderContainer = styled(Header)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
  overflow-x: auto;

  @media (max-width: 768px) {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    min-height: 0;

    &::before {
      background: none;
      width: unset;
      content: none;
    }
  }

  &::before {
    background: none;
    width: unset;
  }
`

export const TransfersHeader = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()
  const { isUserAllowedToCreateBeneficiary } = useBusinessAccountContext()

  const historyPath = "/transfers/transactions"
  const beneficiariesPath = "/transfers/beneficiaries"
  const isHistorySelected = location.pathname.startsWith(historyPath)
  const isBeneficiariesSelected = location.pathname.startsWith(beneficiariesPath)

  return (
    <HeaderContainer>
      <Typography $variant="title-3-semibold">{t("transfers.header.title")}</Typography>
      <TransfersHeaderTabs />
      {isBeneficiariesSelected && isUserAllowedToCreateBeneficiary && <AddBeneficiaryHeaderButton />}
      {isHistorySelected && <TransfersHeaderButton />}
    </HeaderContainer>
  )
}

const TransfersHeaderButton = () => {
  const { isBaActive, navigateToCreateTransfer, isUserAllowedToCreateTransfers } = useBusinessAccountContext()
  const { t } = useCommonTranslation()

  return (
    <AbsoluteBtnPosition>
      {isUserAllowedToCreateTransfers && (
        <Button
          size="small"
          isLoading={false}
          $variant="primary"
          disabled={!isBaActive}
          onClick={() => navigateToCreateTransfer({ updateTransferOrigin: true })}
        >
          {t("wire.history.action")}
        </Button>
      )}
    </AbsoluteBtnPosition>
  )
}

const AddBeneficiaryHeaderButton = () => {
  const { t } = useDashboardTranslation()
  const { isBaActive } = useBusinessAccountContext()
  const { track } = useAmplitude()
  const { isUserAllowedToCreateBeneficiary } = useBusinessAccountContext()

  return (
    <AbsoluteBtnPosition>
      {isUserAllowedToCreateBeneficiary && (
        <Button
          as={Link}
          to="/transfers/beneficiaries/create"
          size="small"
          isLoading={false}
          $variant="primary"
          disabled={!isBaActive}
          onClick={() => track("transfer_beneficiary_add_a_beneficiary_clicked")}
        >
          {t("transfers.header.addBeneficiaryButton")}
        </Button>
      )}
    </AbsoluteBtnPosition>
  )
}

const AbsoluteBtnPosition = styled.div`
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 768px) {
    position: absolute;
    right: 1rem;
    top: 1rem;
    transform: none;
  }
`
