import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { ApHistoryTypeEnum, ApRegulatedStatusEnum } from "../../AcceleratedPayout/00_shared/types/types"

const query = gql`
  query getApDetails($aprId: String!) {
    getApDetails(aprId: $aprId) {
      ... on GetApDetailsOutput {
        financedAmount
        periodStartAt
        periodEndAt
        status
        periodRevenue
        financingRate
        feeRate
        feeAmount
        vatAmount
        createdAt
        history {
          type
          date
          amount
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

interface AcceleratedPayoutDetails {
  financedAmount: number
  periodStartAt: string
  periodEndAt: string
  status: ApRegulatedStatusEnum
  periodRevenue: number
  financingRate: number
  feeRate: number
  feeAmount: number
  vatAmount: number
  createdAt: string
  history: {
    type: ApHistoryTypeEnum
    date: string
    amount?: number
  }[]
}

export const useGetAcceleratedPayoutDetails = (variables: { aprId: string }, useFakeData = false) => {
  const queryResult = useHeroQuery<AcceleratedPayoutDetails>({
    gqlQuerySchema: query,
    variables,
  })

  if (useFakeData) {
    return {
      error: null,
      loading: false,
      data: fakeDetails,
    }
  }

  return queryResult
}

const fakeDetails: AcceleratedPayoutDetails = {
  financedAmount: 2000_78,
  periodStartAt: "2021-09-01T00:00:00Z",
  periodEndAt: "2021-09-30T23:59:59Z",
  status: ApRegulatedStatusEnum.TOTALLY_REPAID,
  periodRevenue: 1000_78,
  financingRate: 0.005,
  feeRate: 0.001,
  feeAmount: 20_78,
  vatAmount: 4_15,
  createdAt: "2021-09-01T00:00:00Z",
  history: [
    {
      type: ApHistoryTypeEnum.INIT,
      date: "2021-09-01T00:00:00Z",
    },
    {
      type: ApHistoryTypeEnum.UNLOCKING_FUNDS,
      amount: 2000_78,
      date: "2021-09-01T00:00:00Z",
    },
    {
      type: ApHistoryTypeEnum.TAKE_FEE,
      amount: 20_78,
      date: "2021-09-01T00:00:00Z",
    },
    {
      type: ApHistoryTypeEnum.TRANSFER_FUNDS_TO_BA,
      amount: 1980_00,
      date: "2021-09-01T00:00:00Z",
    },
    {
      type: ApHistoryTypeEnum.DEBT_REPAYMENT,
      amount: 1000_78,
      date: "2021-09-30T23:59:59Z",
    },
    {
      type: ApHistoryTypeEnum.DEBT_REPAYMENT,
      amount: 10000_0,
      date: "2021-09-30T23:59:59Z",
    },
    {
      type: ApHistoryTypeEnum.DEBT_TOTALLY_REPAID,
      date: "2021-09-30T23:59:59Z",
    },
  ],
}
