import { Button, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import { Card } from "../../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import { useAmplitude } from "../../../../../00_shared/hooks/useAmplitude.hook"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { LedgerMovementFlow } from "../../../00_shared/business"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import TransferSentIcon from "../../00_shared/icons/transfer-sent-icon.png"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"

export const TransferSentScreen = ({
  transactionId,
  transferDetails,
}: {
  transactionId: string
  transferDetails: {
    amount: number
    recipient: string
    transferType: TransferType | undefined
    debitedAccountId: string
  }
}) => {
  const navigate = useNavigate()
  const { t } = useCommonTranslation()
  const { track } = useAmplitude()
  const { clearState } = useCreateTransferContext()
  const { navigateToCreateTransfer, createTransferOrigin } = useBusinessAccountContext()

  const { recipient, transferType, amount } = transferDetails

  const amountInEuros = toEuros(amount)
  const message =
    transferType === TransferType.BENEFICIARY
      ? `${recipient} ${t("transfer.beneficiary.confirmed.subTitle.one")} ${amountInEuros} ${t("transfer.beneficiary.confirmed.subTitle.two")}`
      : `${t("transfer.accountToAccount.confirmed.subTitle.one")} ${recipient} ${t("transfer.accountToAccount.confirmed.subTitle.two")}`

  const isFromListAllBA = createTransferOrigin === "/accounts/transactions"

  const handleClickNewTransfer = () => {
    track(
      isFromListAllBA
        ? "business_account_send_a_transfer_to_a_beneficiary_from_list_all_ba_send_new_transfer_clicked"
        : "business_account_send_a_transfer_to_a_beneficiary_from_transfer_s                                                                   end_new_transfer_clicked",
    )

    clearState()
    navigateToCreateTransfer({ updateTransferOrigin: false })
  }

  const handleClickCheckTransfer = () => {
    track(
      isFromListAllBA
        ? "business_account_send_a_transfer_to_a_beneficiary_from_list_all_ba_see_transfer_clicked"
        : "business_account_send_a_transfer_to_a_beneficiary_from_transfer_see_transfer_clicked",
    )

    if (transferType === TransferType.BENEFICIARY) {
      navigate(`/transfers/transactions/${transactionId}`, {
        state: {
          flow: LedgerMovementFlow.BUSINESS_ACCOUNT_EXTERNAL_PAYOUT,
          ledgerMovementId: "",
          transactionId,
          ledgerId: transferDetails.debitedAccountId,
        },
      })
      return
    }
    navigate(`/accounts/transactions/${transactionId}`, {
      state: {
        flow: LedgerMovementFlow.PRODUCT_LEDGER_TO_PRODUCT_LEDGER,
        ledgerMovementId: "",
        transactionId,
        ledgerId: transferDetails.debitedAccountId,
      },
      replace: true,
    })
  }

  return (
    <Card $variant="plain" style={{ paddingTop: "2rem" }}>
      <FlexContainer $direction="column" $justify="center" $align="center" $gap="2rem">
        <img src={TransferSentIcon} width={80} alt="Virtuelle card" />
        <FlexContainer $direction="column" $gap=".5rem" $align="center" $justify="center">
          <Typography $variant="title-1-bold" $color="grey.$600">
            {t("transfer.confirmed.title")}
          </Typography>
          <Typography $variant="body-3-regular" $color="grey.$500" style={{ maxWidth: "60%", textAlign: "center" }}>
            {message}
          </Typography>
        </FlexContainer>
        <FlexContainer $gap=".5rem" $direction="column" style={{ width: "100%" }}>
          <Button $fullWidth size="medium" onClick={handleClickNewTransfer}>
            {t("transfer.confirmed.button.newTransfer")}
          </Button>
          <Button $fullWidth $variant="tertiary" size="medium" onClick={handleClickCheckTransfer}>
            {t("transfer.confirmed.button.checkTransfer")}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Card>
  )
}
