import { gql } from "@apollo/client"
import { useMutationWith2fa } from "../../../../Auth/Challenge2fa/useMutationWith2fa"

type IssuingCardSetSpendingLimitsArgs = {
  cardId: string
  monthlySpendingLimitAmount?: number
  perTransactionLimitAmount?: number
  dailySpendingLimitAmount?: number
}

type IssuingCardSetSpendingLimitsSuccess = {
  setIssuingCardSpendingLimits: {
    success: boolean
  }
}

type IssuingCardSetSpendingLimitsFunctionalError = {
  setIssuingCardSpendingLimits: {
    errorCode: string
    errorValue: string
  }
}

type IssuingCardSetSpendingLimits2faFunctionalError = {
  setIssuingCardSpendingLimits: {
    errorCode: string
    message: string
    detail: string
  }
}

type IssuingCardSetSpendingLimitsResponse =
  | IssuingCardSetSpendingLimitsSuccess
  | IssuingCardSetSpendingLimitsFunctionalError
  | IssuingCardSetSpendingLimits2faFunctionalError

const ISSUING_CARD_SET_SPENDING_LIMITS = gql`
  mutation setSpendingLimits(
    $cardId: String!
    $monthlySpendingLimitAmount: Int
    $perTransactionLimitAmount: Int
    $dailySpendingLimitAmount: Int
  ) {
    setIssuingCardSpendingLimits(
      cardId: $cardId
      monthlySpendingLimitAmount: $monthlySpendingLimitAmount
      perTransactionLimitAmount: $perTransactionLimitAmount
      dailySpendingLimitAmount: $dailySpendingLimitAmount
    ) {
      ... on SetIssuingCardSpendingLimitsOutput {
        success
        __typename
      }
      ... on ValidationErrors {
        errorCode
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const useCardSetSpendingAmountLimitHook = () => {
  const [setSpendingLimits, { loading }] = useMutationWith2fa<
    IssuingCardSetSpendingLimitsResponse,
    IssuingCardSetSpendingLimitsArgs
  >(ISSUING_CARD_SET_SPENDING_LIMITS)

  return {
    setSpendingLimits,
    loading,
  }
}
