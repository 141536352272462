import { APMovementCategory, APStatus } from "../../../../business/enums/AP.enum"
import { paymentsDemoData } from "../FilledPayments/paymentsDemoData"
import { GetAcceleratedPayoutResult } from "./PaymentDetails.requests"

export const getPaymentDemoData = (apId: string): GetAcceleratedPayoutResult | null => {
  const paymentDemoData = paymentsDemoData.acceleratedPayouts.find((ap) => ap.id === apId)

  if (!paymentDemoData) {
    return null
  }

  const ca = (paymentDemoData.fundedAmount * 100) / 80
  const fee = ca * 0.015

  return {
    ...paymentDemoData,
    account: {
      name: paymentDemoData.marketplace,
      number: "1234567890",
    },
    CA: ca,
    periodeDuCAEnd: addDays(new Date(paymentDemoData.createdAt), -1).toISOString(),
    periodeDuCAStart: addDays(new Date(paymentDemoData.createdAt), -1).toISOString(),
    financingRate: 0.8,
    feesAmount: fee,
    vatAmount: fee * 0.2,
    closingDate:
      paymentDemoData.status === APStatus.FINISHED
        ? addDays(new Date(paymentDemoData.createdAt), 10).toISOString()
        : undefined,
    movements: getMovements(paymentDemoData, ca, fee),
    orders: [
      { orderNumber: "order-df59a637" },
      { orderNumber: "order-2ba0c782" },
      { orderNumber: "order-b18c01b1" },
      { orderNumber: "order-c2655f7c" },
      { orderNumber: "order-94ee5c92" },
      { orderNumber: "order-a1181cb8" },
      { orderNumber: "order-b554141a" },
      { orderNumber: "order-2920653f" },
      { orderNumber: "order-8aa90145" },
      { orderNumber: "order-07bd707e" },
      { orderNumber: "order-4274c936" },
      { orderNumber: "order-c14dca11" },
      { orderNumber: "order-f48c0ce4" },
      { orderNumber: "order-a25b8042" },
      { orderNumber: "order-9f976fcc" },
      { orderNumber: "order-e185e9f1" },
      { orderNumber: "order-d45cc6f9" },
      { orderNumber: "order-023bf0c2" },
      { orderNumber: "order-706cd1cf" },
      { orderNumber: "order-924093ec" },
      { orderNumber: "order-0a7040e2" },
      { orderNumber: "order-f5b028bc" },
      { orderNumber: "order-6ebae7f4" },
      { orderNumber: "order-7fcbf56c" },
      { orderNumber: "order-f8a900ac" },
      { orderNumber: "order-56ddf063" },
      { orderNumber: "order-6eee65d4" },
      { orderNumber: "order-269801de" },
      { orderNumber: "order-97f973a5" },
      { orderNumber: "order-e7da2c54" },
    ],
    marketplace: {
      name: paymentDemoData.marketplace,
      logoUrl: paymentDemoData.marketplaceLogoUrl,
    },
  }
}

function getMovements(paymentDemoData: (typeof paymentsDemoData.acceleratedPayouts)[0], ca: number, fee: number) {
  const movements = [
    {
      id: "acctmov-673072cb-3343-439e-9f14-5e3895dd1cbc",
      createdAt: new Date(paymentDemoData.createdAt).toISOString(),
      category: APMovementCategory.CREDIT_GRANTED,
      amount: paymentDemoData.fundedAmount,
      balanceAfter: paymentDemoData.fundedAmount,
    },
    {
      id: "acctmov-2e9e3522-5320-404b-b88e-fe8a071d040b",
      createdAt: new Date(paymentDemoData.createdAt).toISOString(),
      category: APMovementCategory.CREDIT_FEES,
      amount: ca * -0.015,
      balanceAfter: paymentDemoData.fundedAmount - fee,
    },
  ]

  if (paymentDemoData.status === APStatus.FINISHED) {
    movements.push({
      id: "acctmov-2e9e3522-5320-404b-b88e-fe8a071d040b",
      createdAt: addDays(new Date(paymentDemoData.createdAt), 10).toISOString(),
      category: APMovementCategory.CREDIT_REPAYMENT,
      amount: -paymentDemoData.fundedAmount,
      balanceAfter: 0,
    })
  }

  return movements
}

function addDays(date: Date, days: number): Date {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}
