import { Tab, Tabs } from "@hero/krypton"
import { Link, useLocation, useParams } from "react-router-dom"
import styled from "styled-components"
import useMediaQuery from "../../../../../00_shared/hooks/useMediaQuery.hook"
import { useAmplitude } from "../../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const TabsContainer = styled(Tabs)`
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-around;

    & button,
    a {
      flex: 1;
      text-align: center;
      justify-content: center;
      margin-right: 0;
    }
  }
`

export const AccountHeaderTabs = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { accountNb } = useParams<{ accountNb: string }>()
  const { track } = useAmplitude()

  const transactionsPath = accountNb ? `/accounts/${accountNb}/transactions` : "/accounts/transactions"
  const accountDetailsPath = accountNb ? `/accounts/${accountNb}/details` : undefined
  const bankDetailsSegment = "/bank-details"

  const isTransactionsSelected = location.pathname.startsWith(transactionsPath)
  const isAccountDetailsSelected = location.pathname.startsWith(accountDetailsPath || "")

  return (
    <TabsContainer>
      <Tab as={Link} $isSelected={isTransactionsSelected} to={transactionsPath} data-test-id="merchant-collection">
        {t("accounts.list.tabs.transactions")}
      </Tab>
      {accountDetailsPath ? (
        <Tab
          as={Link}
          $isSelected={isAccountDetailsSelected}
          to={isMobile ? accountDetailsPath : accountDetailsPath + bankDetailsSegment}
          data-test-id="merchant-collection"
          onClick={() => track("business_account_select_an_account_details_clicked")}
        >
          {t("accounts.list.tabs.details")}
        </Tab>
      ) : undefined}
    </TabsContainer>
  )
}
