import { useLocation } from "react-router-dom"
import { useDashboardTranslation } from "../../01_technical/translations"
import { Spacer } from "../../00_shared/components/Spacer"
import { Header, Separator, Typography } from "@hero/krypton"
import styled from "styled-components"
import { UserManagementHeaderTabs } from "./UserManagementHeaderTabs"
import { Members } from "./Members/UserManagementMembers"

const HeaderContainer = styled(Header)`
  position: relative;
  display: flex;
  justify-content: space-after;
  align-items: start;
  overflow-x: auto;
  flex: 1;

  @media (max-width: 768px) {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    min-height: 0;

    &::before {
      background: none;
      width: unset;
      content: none;
    }
  }

  &::before {
    background: none;
    width: unset;
  }
`

export const UserManagementScreen = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()

  const membersPath = "/user-management/members"
  const isMembersSelected = location.pathname.startsWith(membersPath)

  return (
    <HeaderContainer>
      <Typography $variant="title-3-semibold">{t("userManagement.header.title")}</Typography>
      <Typography $variant="body-4-regular">{t("userManagement.header.subtitle")}</Typography>
      <UserManagementHeaderTabs />
      <Separator />
      <Spacer />
      {isMembersSelected && <Members />}
    </HeaderContainer>
  )
}
