import { gql, useLazyQuery } from "@apollo/client"

const GET_BANK_STATEMENT_EXPORT_DATA = gql`
  query getBankStatementExportData($ledgerIds: [String!]!, $startDate: DateTime!, $endDate: DateTime!) {
    getBankStatementExportData(ledgerIds: $ledgerIds, startDate: $startDate, endDate: $endDate) {
      ... on GetBankStatementExportDataOutput {
        bankStatement {
          id
          accountName
          iban
          requestDate
          type
          counterparty
          debit
          credit
          currency
          externalReference
          internalNote
          orderingParty
          hasJustificative
          lastCardDigits
        }
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

type GetBankStatementExportDataInput = {
  ledgerIds: string[]
  startDate: Date
  endDate: Date
}

export interface BankStatementExportDataLine {
  id: string
  accountName: string
  iban: string
  requestDate: Date
  type: string
  counterparty: string
  debit: number | null
  credit: number | null
  currency: string
  externalReference: string | null
  internalNote: string | null
  orderingParty: string | null
  hasJustificative: boolean
  lastCardDigits: string | null
}

type GetBankStatementExportDataOutput = {
  bankStatement: BankStatementExportDataLine[]
}

export const useLazyGetBankStatementExportData = () => {
  const [getBankStatementExportData] = useLazyQuery<{
    getBankStatementExportData:
      | GetBankStatementExportDataOutput
      | {
          errorCode: string
        }
  }>(GET_BANK_STATEMENT_EXPORT_DATA, {
    fetchPolicy: "no-cache",
  })

  return async (args: GetBankStatementExportDataInput): Promise<BankStatementExportDataLine[]> => {
    const query = await getBankStatementExportData({
      variables: {
        ledgerIds: args.ledgerIds,
        startDate: args.startDate,
        endDate: args.endDate,
      },
    })

    const dataResponse = query.data?.getBankStatementExportData

    if (query.error !== undefined) {
      throw new Error(query.error.message)
    }

    if (!dataResponse) {
      return []
    }

    if ("errorCode" in dataResponse) {
      throw new Error(dataResponse.errorCode)
    }

    return dataResponse?.bankStatement
  }
}
