import { useCallback, useEffect, useMemo, useState } from "react"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { Separator, Typography } from "@hero/krypton"
import { Spacer } from "../../../00_shared/components/Spacer"
import { useQuery } from "@apollo/client"
import { ProductCapabilities } from "./UserManagementProductCapabilities"
import { getTranslationKey } from "../00_shared/i18n"
import { Capability } from "../00_shared/Capability"
import { LIST_CAPABILITIES } from "../Capabilities.requests"
import { byPreset } from "../00_shared/rules/ProductCapabilities.rules"
import { Skeleton } from "../../../00_shared/components/Skeleton"

export const InvitationCapabilitiesStrategy = ({
  actor,
  onChange,
}: {
  actor: string
  onChange: (capabilities: Pick<Capability, "action" | "target" | "isAllowed">[]) => void
}) => {
  const [capabilities, setCapabilities] = useState([] as Capability[])
  const { t } = useDashboardTranslation()

  const { data, loading, error } = useQuery(LIST_CAPABILITIES, {
    variables: { actor },
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (!data) {
      return
    }

    setCapabilities(
      data.listCapabilities.capabilities.map(
        ({ action, target, friendlyTargetName }: Omit<Capability, "translationKey">) => ({
          action: action,
          target: target,
          friendlyTargetName,
          isAllowed: false,
          translationKey: getTranslationKey({ action, target }),
        }),
      ),
    )
  }, [data])

  useEffect(() => {
    onChange(capabilities)
  }, [capabilities, onChange])

  const handleChange = useCallback(async ({ action, target, isAllowed }: Capability) => {
    setCapabilities((old) => {
      return old.map((cap) => {
        // If the user is allowed to send external transfers, they should also be allowed to send internal transfers
        if (cap.action === "send-external-transfer" && action === "send-internal-transfer") {
          return { ...cap, isAllowed }
        }
        if (cap.action === action && cap.target === target) {
          return { ...cap, isAllowed }
        }
        return cap
      })
    })
  }, [])

  const capabilityForAccounts = useMemo(() => byPreset(["view-account"], capabilities), [capabilities])
  /* Display only one capability for transfer */
  const otherCapabilities = useMemo(
    () => byPreset(["create-card", "add-beneficiary", "send-internal-transfer"], capabilities),
    [capabilities],
  )

  return (
    <>
      {loading && !error ? (
        <>
          <Spacer />
          <Skeleton
            height="2.5rem"
            width="50%"
            style={{
              marginTop: ".5rem",
            }}
          />
          <Skeleton
            height="1.5rem"
            width="100%"
            style={{
              marginTop: ".5rem",
              marginBottom: ".5rem",
            }}
          />
          <Skeleton
            height="1.5rem"
            width="100%"
            style={{
              marginTop: ".5rem",
              marginBottom: ".5rem",
            }}
          />
          <Skeleton
            height="2.5rem"
            width="50%"
            style={{
              marginTop: ".5rem",
            }}
          />
          <Skeleton
            height="1.5rem"
            width="100%"
            style={{
              marginTop: ".5rem",
              marginBottom: ".5rem",
            }}
          />
          <Skeleton
            height="1.5rem"
            width="100%"
            style={{
              marginTop: ".5rem",
              marginBottom: ".5rem",
            }}
          />
          <Spacer />
        </>
      ) : (
        <>
          {capabilityForAccounts.length > 0 && (
            <>
              <Typography $variant="body-3-semibold">{t("userManagement.productStrategy.accounts.title")}</Typography>
              <Typography $variant="caption-2">{t("userManagement.productStrategy.accounts.description")}</Typography>
              <Spacer />

              <ProductCapabilities
                capabilities={capabilityForAccounts.map((cap) => ({
                  ...cap,
                }))}
                onChange={handleChange}
              />

              <Separator />
              <Spacer />
            </>
          )}
          <Typography $variant="body-3-semibold">{t("userManagement.productStrategy.permissions.title")}</Typography>
          <Spacer />
          <ProductCapabilities capabilities={otherCapabilities} onChange={handleChange} />
        </>
      )}
    </>
  )
}
