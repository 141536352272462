import { gql, useLazyQuery } from "@apollo/client"

const GET_SIGNED_URL = gql`
  query getMerchantBusinessAccountOperationJustificativePresignedUrl(
    $operationId: String!
    $businessAccountId: String!
  ) {
    getMerchantBusinessAccountOperationJustificativePresignedUrl(
      operationId: $operationId
      businessAccountId: $businessAccountId
    ) {
      ... on GetMerchantBusinessAccountOperationJustificativePresignedUrlOutput {
        signedUrl
        path
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

interface fetchSignedUrlOutput {
  signedUrl: string
  path: string
}

export const useOperationJustificativePresignedUrl = () => {
  const [getOperationJustificativePresignedUrl, { data, loading, error }] = useLazyQuery<
    { getMerchantBusinessAccountOperationJustificativePresignedUrl: fetchSignedUrlOutput },
    { operationId: string; businessAccountId: string }
  >(GET_SIGNED_URL)

  const fetchSignedUrl = async (operationId: string, businessAccountId: string) => {
    const result = await getOperationJustificativePresignedUrl({ variables: { operationId, businessAccountId } })
    const data = result?.data?.getMerchantBusinessAccountOperationJustificativePresignedUrl
    return {
      signedUrl: data?.signedUrl,
      path: data?.path,
    } as fetchSignedUrlOutput
  }

  return {
    signedUrl: data?.getMerchantBusinessAccountOperationJustificativePresignedUrl?.signedUrl,
    path: data?.getMerchantBusinessAccountOperationJustificativePresignedUrl?.path,
    loading,
    error,
    fetchSignedUrl,
  }
}
