import { DownloadIcon, ErrorBlock, Field, Label, Typography } from "@hero/krypton"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../../../00_shared/components/Sheet"
import styled from "styled-components"
import { Spacer } from "../../../00_shared/components/Spacer"
import { useExportForm } from "./ExportSheet.utils"
import { Controller } from "react-hook-form"
import { formatDateForInput } from "../utils"
import { useRegulatedApExport } from "../hooks/useRegulatedApExport"
import { useGetInfosForAccountingExport } from "../hooks/getInfosForAccountingExport.hook"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../00_shared/components/Select"
import { Button } from "../../../00_shared/components/krypton2/Button"

const getHoldLedgerAccount = (
  ledgerAccount:
    | {
        id: string
        name: string
        type: "AP_LEDGER_ACCOUNT" | "AP_HOLD_LEDGER_ACCOUNT"
      }[]
    | undefined,
) => {
  return ledgerAccount?.filter((ledger) => ledger.type === "AP_HOLD_LEDGER_ACCOUNT") || []
}

export const ExportSheet = () => {
  const { t } = useDashboardTranslation()
  const form = useExportForm()

  const { data, error, loading } = useGetInfosForAccountingExport()
  const holdLedgerAccount = getHoldLedgerAccount(data?.ledgerAccounts)

  const { exporting, exportAccounting } = useRegulatedApExport()

  const onSubmit = form.handleSubmit((formData) => {
    exportAccounting(holdLedgerAccount, formData.format, {
      fromDatetime: formData.fromDatetime,
      toDatetime: formData.toDatetime,
    })
  })

  const somethingIsloading = exporting || loading

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button $variant="secondary" LeftIcon={DownloadIcon} size="small">
          {t("apRegulated.dashboard.export")}
        </Button>
      </SheetTrigger>
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle>{t("apRegulated.dashboard.exportSheet.title")}</SheetTitle>
        </SheetHeader>

        {error && <ErrorBlock $margin="0 0 2rem 0">{error.translatedMessage}</ErrorBlock>}

        <StyledForm onSubmit={onSubmit}>
          <Controller
            control={form.control}
            name="fromDatetime"
            render={({ field, fieldState }) => (
              <Field
                fieldLabel={t("apRegulated.dashboard.exportSheet.from")}
                type="date"
                $fullWidth
                errorMessage={fieldState.error?.message}
                onBlur={field.onBlur}
                onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(new Date(e.currentTarget.value))}
                value={formatDateForInput(field.value)}
              />
            )}
          />

          <Controller
            control={form.control}
            name="toDatetime"
            render={({ field, fieldState }) => (
              <Field
                fieldLabel={t("apRegulated.dashboard.exportSheet.to")}
                type="date"
                $fullWidth
                errorMessage={fieldState.error?.message}
                onBlur={field.onBlur}
                onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(new Date(e.currentTarget.value))}
                value={formatDateForInput(field.value)}
              />
            )}
          />

          <Select
            onValueChange={(value: "ods" | "xlsx") => {
              form.setValue("format", value)
            }}
            value={form.watch("format")}
          >
            <Label>{t("apRegulated.dashboard.exportSheet.format")}</Label>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="xlsx">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <Typography as="span" $variant="body-4-semibold">
                  Excel (.xlsx)
                </Typography>
              </SelectItem>
              <SelectItem value="ods">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <Typography as="span" $variant="body-4-semibold">
                  OpenDocument (.ods)
                </Typography>
              </SelectItem>
            </SelectContent>
          </Select>

          <Controller
            control={form.control}
            name="includeAccounting"
            render={({ field: { value, onChange } }) => (
              <CheckboxGroup $isChecked={value}>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="accounting-export-check"
                    id="accounting-export-check"
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked)
                    }}
                  />
                </div>
                <div>
                  <Typography $variant="body-4-medium">
                    {t("apRegulated.dashboard.exportSheet.accountingExport")}
                  </Typography>
                  <Typography $variant="caption-2">
                    {t("apRegulated.dashboard.exportSheet.accountingExportDescription")}
                  </Typography>
                </div>
              </CheckboxGroup>
            )}
          />

          <div
            style={{
              flex: 1,
            }}
          />

          <Spacer $height="2rem" />

          <Button
            type="submit"
            $fullWidth
            size="medium"
            disabled={!form.formState.isValid || Boolean(error)}
            isLoading={somethingIsloading}
          >
            {t("apRegulated.dashboard.exportSheet.export")}
          </Button>
        </StyledForm>
      </SheetContent>
    </Sheet>
  )
}

const CheckboxGroup = styled.label<{ $isChecked?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  border: 1px solid ${({ theme, $isChecked }) => ($isChecked ? theme.colors.grey.$600 : theme.colors.grey.$200)};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;

  transition: all 0.2s;

  & * {
    transition: all 0.2s;
  }
`

const Checkbox = styled.input`
  margin-top: 0.3125rem;
  height: 0.875rem;
  width: 0.875rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};

  accent-color: ${({ theme }) => theme.colors.grey.$600};
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`
