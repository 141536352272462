import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"

// GraphQL query to get merchant users, business accounts, and merchant address
const GET_CARD_CONFIGURATION_DATA = gql`
  query getCardConfigurationData {
    getmerchant {
      id
      tradingName
      address {
        line1
        line2
        zipCode
        city
        countryCode
      }
    }
    listMerchantUsers {
      id
      firstname
      lastname
      email
      status
    }
    getMerchantBusinessAccounts {
      ... on GetMerchantBusinessAccountsOutput {
        businessAccounts {
          name
          balance
          ledgerId
          virtualIbanId
          isMainAccount
          createdAt
        }
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

// Define TypeScript types for the data structure
interface Address {
  line1: string
  line2?: string
  zipCode: string
  city: string
  countryCode: string
}

interface Merchant {
  id: string
  tradingName: string
  address: Address | null
}

interface MerchantUser {
  id: string
  firstname: string
  lastname: string
  email: string
  status: string
  hasPendingInvitation: boolean
}

interface BusinessAccount {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  isMainAccount: boolean
  createdAt: Date
}

// Result types for the query
interface GetCardConfigurationDataResult {
  getmerchant: Merchant
  listMerchantUsers: MerchantUser[]
  getMerchantBusinessAccounts: {
    businessAccounts: BusinessAccount[]
  }
}

export const useCardConfiguration = () => {
  const { t } = useCommonTranslation()
  const { currentUser } = useAuthContext()

  // Use Apollo's useQuery hook to fetch the data
  const { data, loading, error, refetch } = useQuery<GetCardConfigurationDataResult>(GET_CARD_CONFIGURATION_DATA, {
    fetchPolicy: "cache-and-network",
  })

  // Filter users based on the ACTIVE or INVITED status
  const filteredMerchantUsers =
    data?.listMerchantUsers?.filter((user) => ["ACTIVE"].includes(user.status) || user.hasPendingInvitation) || []

  // Check if the current user is already in the list
  const currentUserInList = filteredMerchantUsers.some((user) => user.id === currentUser.id)

  // Update the list of users by adding the current user if not already present
  const updatedMerchantUsers = currentUserInList
    ? filteredMerchantUsers
    : [
        ...filteredMerchantUsers,
        {
          id: currentUser.id,
          firstname: currentUser.firstname,
          lastname: currentUser.lastname,
          email: currentUser.email,
          status: "ACTIVE", // Assuming current user is always active
        },
      ]

  const updatedBusinessAccounts = useMemo(() => {
    if (data && data.getMerchantBusinessAccounts.businessAccounts.length) {
      const accounts = data.getMerchantBusinessAccounts.businessAccounts.map((account) => ({
        ...account,
        name: account.isMainAccount ? t("onboarding.businessAccount.name") : account.name,
      }))
      const mainAccount = accounts.find((account) => account.isMainAccount)
      const otherAccounts = accounts
        .filter((account) => !account.isMainAccount)
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

      return mainAccount ? [mainAccount, ...otherAccounts] : otherAccounts
    }
    return []
  }, [data, t])

  return {
    merchant: data?.getmerchant,
    merchantUsers: updatedMerchantUsers,
    businessAccounts: updatedBusinessAccounts,
    loading,
    error,
    refetch,
  }
}
