import { AddIcon, Spinner } from "@hero/krypton"
import { Dialog, DialogContent } from "../../../00_shared/components/Dialog"
import { SearchInput } from "../../../00_shared/components/SearchInput"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { ChangeEvent, useState } from "react"
import { useQuery } from "@apollo/client"
import { useAuthContext } from "../../../Auth/auth.context"
import { LIST_MERCHANT_USERS } from "../../Tools/Settings/MerchantUser/listMerchantUser.requests"
import styled from "styled-components"
import { Button } from "../../../00_shared/components/krypton2/Button"
import { MembersList } from "./UserManagementMembersList"
import { InviteUser } from "./UserManagementInviteUser"
import { SlidingRightPanel } from "./UsermanagementSlidingRightPanel"
import { ContextPanel } from "./UserManagementContextPanel"
import { User } from "../00_shared/User"

const Block = styled.section`
  display: flex;
  gap: 0.5rem;
`

const MembersWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.75rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Members = () => {
  const [searchString, setSearchString] = useState("")
  const { t } = useDashboardTranslation()
  const [sidePanelOpenedForUserId, setSidePanelOpenedForUserId] = useState<string | null>(null)
  const { impersonateBannerHeight, currentUser } = useAuthContext()
  const { data, loading, refetch: refetchUserList } = useQuery<{ listMerchantUsers: User[] }>(LIST_MERCHANT_USERS)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  if (loading) {
    return <Spinner />
  }

  const users = data?.listMerchantUsers ?? []
  const sidePanelUser = users.find((user) => user.id === sidePanelOpenedForUserId)

  return (
    <>
      <Dialog open={isInviteModalOpen} onOpenChange={setIsInviteModalOpen}>
        <DialogContent
          disableCloseButton={false}
          $disableAnimations={true}
          width="100%"
          height="100%"
          topOffset={impersonateBannerHeight}
        >
          <InviteUser
            strawmanUserId={currentUser.id}
            onInviteSuccess={() => {
              setIsInviteModalOpen(false)
              refetchUserList()
            }}
          />
        </DialogContent>
      </Dialog>

      {sidePanelUser && (
        <SlidingRightPanel
          refetchUserList={refetchUserList}
          user={sidePanelUser}
          onClose={() => setSidePanelOpenedForUserId(null)}
        />
      )}

      <Block>
        <SearchInput
          placeholder={t("userManagement.list.searchPlaceholder")}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value)}
        />

        {currentUser.role === "owner" ? (
          <Button onClick={() => setIsInviteModalOpen(true)} $variant="tertiary" LeftIcon={AddIcon}>
            {t("userManagement.list.addMember")}
          </Button>
        ) : null}
      </Block>

      <MembersWrapper>
        <MembersList
          users={users}
          searchString={searchString}
          seeMore={(user) => setSidePanelOpenedForUserId(user.id)}
          editUser={(user) => setSidePanelOpenedForUserId(user.id)}
        />
        <ContextPanel />
      </MembersWrapper>
    </>
  )
}
