import { Spinner, Table, TBody, THead, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ScrollArea } from "../../../../../00_shared/components/ScrollArea"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { LedgerMovementFlow } from "../../../00_shared/business"
import { PaginationContainer } from "../../../00_shared/components/PaginationContainer"
import { ExternalTransfer } from "../hooks/useExternalTransfers"

const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-top: none;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, max-content) min-content;
  overflow-y: hidden;
`

const StaticTable = styled(Table)`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: unset;
  border: none;

  thead tr th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.grey.$100};
    border-top: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }

  thead tr th:first-child {
    border-top-left-radius: 1rem;
  }

  thead tr th:last-child {
    border-top-right-radius: 1rem;
  }
`

const CenteringTD = styled.td`
  text-align: center;
`

const AmountContainer = styled(Typography).attrs({ $variant: "body-4-medium" })<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) => ($isNegative ? theme.colors.grey.$600 : theme.colors.success.$200)};
`

interface TransferHistoryTableProps {
  externalTransfers: ExternalTransfer[]
  pagination: {
    currentPage: number
    totalPages: number
  }
  isLoading: boolean
  onPageChange: (pageNumber: number) => void
}

export const TransferHistoryTable = ({
  externalTransfers,
  pagination,
  isLoading,
  onPageChange,
}: TransferHistoryTableProps) => {
  const navigate = useNavigate()
  const { t } = useCommonTranslation()

  const handleClickTransaction = (args: {
    transactionId: string
    flow: LedgerMovementFlow
    ledgerMovementId: string
    ledgerId: string
  }) => {
    const { transactionId, flow, ledgerMovementId, ledgerId } = args
    navigate(`./${transactionId}`, {
      state: { transactionId, ledgerMovementId, flow, ledgerId },
    })
  }

  return (
    <TableContainer>
      <ScrollArea>
        <StaticTable>
          <THead>
            <tr>
              <th>{t("externalTransfer.label.beneficiary")}</th>
              <th>{t("externalTransfer.label.date")}</th>
              <th>{t("externalTransfer.label.amount")}</th>
              <th>{t("externalTransfer.label.reference")}</th>
            </tr>
          </THead>
          <TBody $clickable={!!externalTransfers.length}>
            {isLoading && (
              <tr>
                <CenteringTD colSpan={5}>
                  <Spinner />
                </CenteringTD>
              </tr>
            )}
            {!externalTransfers.length && !isLoading && (
              <tr>
                <CenteringTD colSpan={5}>{"No transactions found."}</CenteringTD>
              </tr>
            )}
            {externalTransfers.map((transfer) => (
              <tr
                key={transfer.id}
                onClick={handleClickTransaction.bind(null, {
                  transactionId: transfer.id,
                  flow: transfer.flow,
                  ledgerMovementId: transfer.ledgerMovementId,
                  ledgerId: transfer.ledgerId,
                })}
              >
                <td>
                  <Typography $variant="body-4-regular">{transfer.beneficiary}</Typography>
                </td>
                <td>
                  <Typography $variant="body-4-regular">{transfer.sentAt}</Typography>
                </td>
                <td>
                  <AmountContainer $isNegative={true}>{`${transfer.amount}`}</AmountContainer>
                </td>
                <td>
                  <Typography $variant="body-4-regular">{transfer.reference}</Typography>
                </td>
              </tr>
            ))}
          </TBody>
        </StaticTable>
      </ScrollArea>
      <PaginationContainer
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
      />
    </TableContainer>
  )
}
