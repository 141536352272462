import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"
import { apRegulatedBreakpoints as breakpoints } from "./utils"
import { AcceleratedPayoutsSection } from "./components/AcceleratedPayoutsSection"
import { MarketplaceTransfersOverview } from "./components/MarketplaceTransfersOverview"
import { AcceleratedPayoutDetails } from "./components/AcceleratedPayoutDetails"
import { useState } from "react"
import { AprContextProvider } from "./AcceleratedPayoutRegulated.context"

export const HomeScreen = () => {
  const { t } = useDashboardTranslation()
  const [detailsContext, setDetailsContext] = useState({
    modalOpen: false,
    aprId: "",
  })

  return (
    <AprContextProvider>
      <PageWrapper>
        <Header>
          <Typography $variant="title-3-inter-bold">{t("apRegulated.home.title")}</Typography>
        </Header>

        <PageContent>
          <PageContentWrapper>
            <Wrapper>
              <AcceleratedPayoutsColumn>
                <AcceleratedPayoutsSection setDetailsContext={setDetailsContext} />
              </AcceleratedPayoutsColumn>
              <MarketplaceTransfersColumn>
                <MarketplaceTransfersOverview />
              </MarketplaceTransfersColumn>
            </Wrapper>

            <AcceleratedPayoutDetails
              detailsContext={detailsContext}
              close={() =>
                setDetailsContext((old) => ({
                  ...old,
                  modalOpen: false,
                }))
              }
            />
          </PageContentWrapper>
        </PageContent>
      </PageWrapper>
    </AprContextProvider>
  )
}

const Header = styled.header`
  padding: 1.5rem 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1.5rem;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    padding: 1rem;
  }
`

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
`

const PageContent = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

const PageContentWrapper = styled.main`
  padding: 1.5rem 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1.5rem;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    padding: 0rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 0.75rem;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    gap: 0rem;
  }
`

const AcceleratedPayoutsColumn = styled.div`
  flex: 2;

  @media (max-width: ${breakpoints.tablet}) {
    flex: 1;
  }
`

const MarketplaceTransfersColumn = styled.div`
  flex: 1;
`
