import { DateTime } from "luxon"
import { CommonTFunction } from "../../../../01_technical/translations"
import { UserLocale } from "../../../../business/enums/User.enum"
import { LedgerMovementFlow, MerchantOperation, OperationType } from "../../00_shared/business"

const centsToEuros = (cents: number) => {
  return cents / 100
}

export const currencyFormatter = (amountInCents: number) => {
  return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(centsToEuros(amountInCents))
}

interface FormatOperationOptionsProps {
  operation: MerchantOperation
  t: CommonTFunction
  local: UserLocale
}

export const formatMovementOperations = ({
  operations,
  t,
  local,
}: {
  operations: MerchantOperation[]
  t: CommonTFunction
  local: UserLocale
}) => {
  return operations.map((operation) => formatMovementOperation({ operation, t, local }))
}

const formatMovementOperation = ({ operation, t, local }: FormatOperationOptionsProps) => {
  let formattedOperationType: string
  if (
    operation.flow === LedgerMovementFlow.ISSUING_RESERVATION &&
    operation.operationType === OperationType.ACCOUNT_TO_ACCOUNT_OUT
  ) {
    formattedOperationType = t("account.operation.type.card.out")
  } else {
    formattedOperationType = forEnum(operation.operationType, {
      ACCOUNT_TO_ACCOUNT_IN: () => t("account.operation.type.ata.in"),
      ACCOUNT_TO_ACCOUNT_OUT: () => t("account.operation.type.ata.out"),
      SEPA_CREDIT_TRANSFER_IN: () => t("account.operation.type.sct.in"),
      SEPA_CREDIT_TRANSFER_OUT: () => t("account.operation.type.sct.out"),
      FEES_OUT: () => t("account.operation.type.monthly.fees.out"),
      CARD: () => t("account.operation.type.card.generic"),
    })
  }

  const label = isOutgoingOperation(operation.operationType)
    ? operation.creditorName
    : operation.debtorName || "Debtor Name"

  return {
    ledgerId: operation.ledgerId,
    id: operation.id,
    creditor: operation.creditorName,
    debtor: operation.debtorName,
    label: label,
    isNegative: operation.amount < 0,
    amount: `${operation.amount > 0 ? "+" : ""}${new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(operation.amount / 100)}`,
    createdAt: DateTime.fromJSDate(new Date(operation.createdAt)).setLocale(local).toLocaleString(DateTime.DATE_MED),
    operationType: formattedOperationType,
    hasJustificative: !!operation.justificativePath,
    isExternalTransfer: operation.isExternalTransfer,
    flow: operation.flow,
    ledgerMovementId: operation.ledgerMovementId,
    isHeroTransfer: operation.isHeroTransfer,
  }
}

const isOutgoingOperation = (operationType: OperationType) => {
  return (
    operationType === OperationType.ACCOUNT_TO_ACCOUNT_OUT ||
    operationType === OperationType.SEPA_CREDIT_TRANSFER_OUT ||
    operationType === OperationType.FEES_OUT
  )
}

const forEnum = <EnumValue extends string | number, ReturnType, Callbacks extends Record<EnumValue, () => ReturnType>>(
  enumValue: EnumValue,
  callbacks: Callbacks,
): ReturnType => {
  const cb = callbacks[enumValue]
  if (!cb) {
    throw new Error("UNKNOWN_ENUM_VALUE")
  }
  return cb()
}
