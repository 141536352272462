import { useMutation } from "@apollo/client"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { User } from "../00_shared/User"
import { RESEND_MERCHANT_USER_INVITATION } from "../../Tools/Settings/MerchantUser/listMerchantUser.requests"
import { SendIcon, toaster, Typography } from "@hero/krypton"
import { Button } from "../../../00_shared/components/krypton2/Button"

export const InvitationButton = ({ user }: { user: User }) => {
  const { t } = useDashboardTranslation()
  const [resendInvitation] = useMutation(RESEND_MERCHANT_USER_INVITATION)
  const resendInviteToUser = ({ id }: Pick<User, "id">) => {
    toaster.promise(
      resendInvitation({ variables: { userId: id } }),
      {
        pending: t("merchant.tools.setting.merchantUser.invited.pending"),
        success: t("merchant.tools.setting.merchantUser.invited.success"),
        error: t("merchant.tools.setting.merchantUser.invited.error"),
      },
      { position: "top-center" },
    )
  }

  return (
    <Button
      style={{ padding: "0.5rem", backgroundColor: "#F7F7FF" }}
      $plain
      $variant="secondary"
      LeftIcon={SendIcon}
      onClick={() => {
        resendInviteToUser(user)
      }}
    >
      <Typography $variant="body-3-medium" as="span" $color="grey.$600">
        {t("userManagement.row.resendInvitation")}
      </Typography>
    </Button>
  )
}
