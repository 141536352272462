import { ErrorBlock } from "@hero/krypton"
import { useRouteError } from "react-router-dom"
import { useDashboardTranslation } from "../../01_technical/translations"

export function GenericErrorBoundary() {
  const error = useRouteError()
  const { t } = useDashboardTranslation()

  return (
    <ErrorBlock $margin="2rem">
      {t("genericError.appCrashed")}
      {error ? (
        <>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <br />
          Reason: {getErrorMessage(error)}
        </>
      ) : (
        ""
      )}
    </ErrorBlock>
  )
}

function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message
  }

  if (typeof error === "string") {
    return error
  }

  return JSON.stringify(error)
}
