import { gql } from "@apollo/client"

export const INVITE_USER_MERCHANT_MUTATION_NEW_USER_MANAGEMENT = gql`
  mutation merchantOwnerInviteUser(
    $firstname: String!
    $lastname: String!
    $email: String!
    $phone: String!
    $role: MerchantUserRole
    $capabilities: [MerchantUserInvitedCapability!]
  ) {
    merchantOwnerInviteUser(
      firstname: $firstname
      lastname: $lastname
      email: $email
      phone: $phone
      role: $role
      capabilities: $capabilities
    ) {
      id
      firstname
      lastname
      email
      phone
    }
  }
`
