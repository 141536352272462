import { Button, Header, Tab, Tabs, Typography } from "@hero/krypton"
import { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"
import { HEADER_COLORS } from "../../../env_variables"
import { useIsCreditWireTransferActive } from "../../merchant.menu.requests"
import { CwtListCards } from "./ListCards/ListCards"
import { CwtListTable } from "./ListTable/ListTable"
import { CwtNotActivatedScreen } from "./NotActivated/CwtNotActivated.screen"

export const CwtListScreen = () => {
  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const { data: isCreditWireTransferActive } = useIsCreditWireTransferActive({ merchantId: currentUser.merchantId })

  const [searchParams, setSearchParams] = useSearchParams()

  const status = searchParams.get("status")

  useEffect(() => {
    if (!status) {
      setSearchParams({ status: "ONGOING" })
    }
  }, [status, setSearchParams])

  if (!isCreditWireTransferActive) {
    return null
  }

  return (
    <PageWrapper>
      <Header $colors={HEADER_COLORS}>
        <Row>
          <Typography $variant="title-3-semibold">{t("creditWireTransfer.list.title")}</Typography>
          {isCreditWireTransferActive.isActivated ? (
            <Button<typeof Link> size="small" to="/cwt/application" as={Link} isLoading={false}>
              {t("creditWireTransfer.list.buttons.application")}
            </Button>
          ) : (
            <Button disabled>{t("creditWireTransfer.list.button.notEligible")}</Button>
          )}
        </Row>

        {isCreditWireTransferActive.isActivated && (
          <Tabs>
            <Tab<typeof Link>
              as={Link}
              $isSelected={status === "ONGOING"}
              to="/cwt?status=ONGOING&page=1"
              data-test-id="merchant-ap-balance-ONGOING-tab"
            >
              {t("creditWireTransfer.list.tabs.ongoing")}
            </Tab>
            <Tab<typeof Link>
              as={Link}
              $isSelected={status === "COMPLETED"}
              to="/cwt?status=COMPLETED&page=1"
              data-test-id="merchant-ap-balance-COMPLETED-tab"
            >
              {t("creditWireTransfer.list.tabs.closed")}
            </Tab>
            <Tab<typeof Link>
              as={Link}
              $isSelected={status === "REJECTED"}
              to="/cwt?status=REJECTED&page=1"
              data-test-id="merchant-ap-balance-REJECTED-tab"
            >
              {t("creditWireTransfer.list.tabs.refused")}
            </Tab>
          </Tabs>
        )}
      </Header>

      <Relative>
        <PageContent>
          {/* hidden in mvp */}
          {false && <CwtListCards />}
          {isCreditWireTransferActive.isActivated && <CwtListTable />}
          {!isCreditWireTransferActive.isActivated && <CwtNotActivatedScreen />}
        </PageContent>
      </Relative>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Relative = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
`

const PageContent = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 3rem;
  flex: 1;
`
