import { BEAUTIFUL_USER_MANAGEMENT_ENABLED } from "../../env_variables"
import { MerchantMenuEntry } from "../merchant.menu.type"

export const UserManagementMenuEntry: MerchantMenuEntry = {
  to: "/user-management",
  key: "menu.list.user-management",
  visible: BEAUTIFUL_USER_MANAGEMENT_ENABLED, // enable this when the feature is ready
  selected: (location) => location.pathname.startsWith("/user-management"),
  "data-test-id": "menu-entry-user-management",
}
