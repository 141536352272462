import { AddIcon } from "@hero/krypton"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { EmptyState } from "../../../00_shared/components/EmptyState"
import { useAmplitude } from "../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useCapability } from "../../../Auth/auth.context"
import { useBusinessAccountContext } from "../../BusinessAccount/businessAccount.context"
import { AdviceToDownloadAppCard } from "../00_shared/components/AdviceToDownloadAppCard"
import TransferIcon from "../00_shared/icons/transfer-icon.png"
import { useIssuingCardListHook } from "./IssuingCardList.hook"
import { IssuingCardsTable } from "./components/IssuingCardsTable"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 768px) {
    flex: 1;
  }
`

const IssuingCardContainer = ({ children }: { children: React.ReactNode }) => {
  return <Container id="foofoo">{children}</Container>
}

export const IssuingCardList = () => {
  const navigate = useNavigate()
  const { isBaActive } = useBusinessAccountContext()
  const { cards, loading } = useIssuingCardListHook()
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()
  const canCreateCard = useCapability("create-card", "any")

  const createCards = () => {
    track("card_create_card_from_card_page_clicked")
    navigate("/cards/create")
  }

  if (!loading && !cards.length) {
    return (
      <IssuingCardContainer>
        <EmptyState
          illustration={<img src={TransferIcon} alt={t("issuing.card.empty.img.alt")} width={100} />}
          title={t("issuing.card.empty.title")}
          text={t("issuing.card.empty.text")}
          buttonText={t("issuing.card.action.create")}
          buttonIcon={<AddIcon />}
          disabledButton={!isBaActive || !canCreateCard}
          onButtonClick={createCards}
        />
      </IssuingCardContainer>
    )
  }

  return (
    <IssuingCardContainer>
      {cards.length >= 1 && <AdviceToDownloadAppCard />}
      <IssuingCardsTable cards={cards} isLoading={loading} />
      <Outlet />
    </IssuingCardContainer>
  )
}
