import { ChevronRightIcon } from "@hero/krypton"
import styled from "styled-components"
import wave from "./assets/wave.png"

const getPagesToDisplay = ({
  currentPage,
  totalPageNumber,
}: {
  totalPageNumber: number
  currentPage: number
}): (number | null)[] => {
  const protectedPages: number[] = [1, totalPageNumber, currentPage]

  if (totalPageNumber <= 5) {
    return Array.from({ length: totalPageNumber }, (_, i) => i + 1)
  }

  if (currentPage === 1) {
    protectedPages.push(2)
    protectedPages.push(3)
  }

  if (currentPage === 2) {
    protectedPages.push(3)
  }

  if (currentPage === totalPageNumber) {
    protectedPages.push(totalPageNumber - 1)
    protectedPages.push(totalPageNumber - 2)
  }

  if (currentPage === totalPageNumber - 1) {
    protectedPages.push(totalPageNumber - 2)
  }

  const result: (number | null)[] = []
  for (let i = 1; i <= totalPageNumber; i++) {
    if (!protectedPages.includes(i)) {
      result.push(null)
      continue
    }
    result.push(i)
  }

  for (let i = 0; i < result.length; i++) {
    if (result[i] === null && result[i + 1] === null) {
      result.splice(i, 1)
      i--
    }
  }

  return result
}

export const Pagination = ({
  onClick: _onClick,
  totalPageNumber,
  currentPage,
}: {
  onClick: (pageNumber: number) => void
  totalPageNumber: number
  currentPage: number
}) => {
  const pagesToDisplay = getPagesToDisplay({ totalPageNumber, currentPage })

  return (
    <PageWrapper>
      <UnstyledButton
        onClick={() => {
          const previousPage = currentPage - 1

          if (previousPage < 1) {
            return
          }

          _onClick(previousPage)
        }}
      >
        <ChevronLeftIcon />
      </UnstyledButton>
      {pagesToDisplay.map((pageNumber) => {
        if (pageNumber === null) {
          return <EmptyPage />
        }

        return (
          <Page key={pageNumber} selected={pageNumber === currentPage} onClick={() => _onClick(pageNumber)}>
            {pageNumber}
          </Page>
        )
      })}
      <UnstyledButton>
        <ChevronRightIcon
          onClick={() => {
            const nextPage = currentPage + 1

            if (nextPage > totalPageNumber) {
              return
            }

            _onClick(nextPage)
          }}
        />
      </UnstyledButton>
    </PageWrapper>
  )
}

const EmptyPage = () => {
  return <Page selected={false}>...</Page>
}

const UnstyledButton = styled.button`
  border: none;
  background-color: transparent;
  height: 1.5rem;
  width: 1.5rem;
  color: ${({ theme }) => theme.colors.grey.$400};
  cursor: pointer;
`

const Page = styled.button.attrs({ type: "button" })<{ selected?: boolean }>`
  cursor: pointer;
  border: none;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.secondary.$200 : "white")};
  color: ${({ selected, theme }) => (selected ? "white" : theme.colors.grey.$400)};
  line-height: 24px;
  font-weight: 600;

  ${({ selected }) =>
    selected &&
    `
    background-image: url(${wave});
    background-size: 200%;
    background-position: 0 0;
    background-repeat: repeat;
  `}
`

const PageWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`

const ChevronLeftIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`
