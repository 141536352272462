import { Button, Header, InfoIcon, MetricItem, Tooltip, Typography } from "@hero/krypton"
import { DateTime } from "luxon"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Skeleton } from "../../../../../00_shared/components/Skeleton"
import { useIsYieldEnabled } from "../../../../../00_shared/hooks/useFeatureFlag.hook"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useCapability } from "../../../../../Auth/auth.context"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import { useBusinessAccount } from "../hooks/useBusinessAccount"
import { useBusinessAccounts } from "../hooks/useBusinessAccounts"
import { useGetMerchantYieldInformations } from "../hooks/useMerchantYieldInformations"
import { currencyFormatter } from "../utils"
import { AccountClosedBanner } from "./AccountClosedBanner/AccountClosedBanner"
import { AccountDrawerContent } from "./AccountDrawer/AccountDrawerContent"
import { AccountHeaderTabs } from "./AccountHeaderTabs"

const DesktopHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderWithoutGradient = styled(Header)`
  &::before {
    background: none;
  }

  [role="tooltip"] {
    z-index: 10;
  }

  @media (max-width: 768px) {
    min-height: unset;
    padding: 1rem 1rem 0 1rem;

    &:before {
      display: none;
    }
  }
`

const DesktopTotalAmountWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`

const MobileTotalAmountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`

const TotalAmount = styled(Typography).attrs(() => ({
  $variant: "title-3-inter-bold",
}))`
  color: ${({ theme }) => theme.colors.black.$900};
  margin: 0;
`

const MobileHeader = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const DrawerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  button {
    min-width: unset !important;
  }
`

const CustomFlexContainer = styled.div<{ $justify: string }>`
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  width: 100%;
`

const DesktopHeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DesktopYieldWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
`

const BadgeYieldTransactionNeeded = styled.div`
  width: fit-content;
  display: flex;
  padding: 0rem 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.secondary.$100};
`

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th" // 4th - 20th
  switch (day % 10) {
    case 1:
      return "st" // 1st, 21st, 31st
    case 2:
      return "nd" // 2nd, 22nd
    case 3:
      return "rd" // 3rd, 23rd
    default:
      return "th" // 4th, 5th, etc.
  }
}

const getDateFormat = (lang: string, date: Date) => {
  switch (lang) {
    case "fr":
      return "dd MMMM yyyy" // Example: 21 novembre, 2024
    case "en":
      return `MMMM dd'${getOrdinalSuffix(date.getDay())}' yyyy` // Example: November 21th 2024
    case "de":
      return "dd. MMMM yyyy" // Example: 21. November 2024
    case "it":
      return "dd MMMM yyyy" // Example: 21 novembre 2024
    default:
      return "yyyy-MM-dd" // Fallback format
  }
}

export const AccountHeader = () => {
  const { t: tc } = useCommonTranslation()
  const { t } = useDashboardTranslation()
  const { isBaActive, navigateToCreateTransfer } = useBusinessAccountContext()
  const canInternalTransfer = useCapability("send-internal-transfer", "any")
  const canExternalTransfer = useCapability("send-external-transfer", "any")
  const canTransfer = canInternalTransfer || canExternalTransfer
  const { totalBalance: aggregatedTotalBalance, loading: loadingBusinessAccounts } = useBusinessAccounts()
  const { businessAccount, loading: loadingBusinessAccount } = useBusinessAccount()
  const { accountNb } = useParams<{ accountNb: string }>()
  const isEnabled = useIsYieldEnabled()
  const { data: yieldInformations } = useGetMerchantYieldInformations(!isEnabled)
  const lang = document.documentElement.lang

  const isAllBusinessAccount = accountNb === undefined

  const isLoading = isAllBusinessAccount ? loadingBusinessAccounts : loadingBusinessAccount

  const totalBalance = isAllBusinessAccount
    ? aggregatedTotalBalance
    : businessAccount !== undefined
      ? businessAccount.balance
      : 0

  return (
    <HeaderWithoutGradient>
      <MobileHeader>
        <TitleContainer>
          <Typography $variant="title-3-semibold">{t("accounts.mobileHeader.title")}</Typography>
          <Button
            onClick={() => navigateToCreateTransfer({ updateTransferOrigin: true })}
            size="small"
            isLoading={false}
            disabled={!isBaActive || !canTransfer}
          >
            {t("accounts.buttonTransfer.text")}
          </Button>
        </TitleContainer>
        <DrawerContainer>
          <AccountDrawerContent />
          <CustomFlexContainer $justify="space-between">
            <MobileTotalAmountWrapper>
              {isLoading && <Skeleton height="2.25rem" width="6.5rem" />}
              {!isLoading && (
                <>
                  <TotalAmount>{currencyFormatter(totalBalance)}</TotalAmount>
                  <Tooltip id="total-balance-info-mobile" content={tc("accounts.fundsSafeNotice")}>
                    <InfoIcon width={24} />
                  </Tooltip>
                </>
              )}
            </MobileTotalAmountWrapper>
          </CustomFlexContainer>
        </DrawerContainer>
        {businessAccount?.accountClosedAt && <AccountClosedBanner closingDate={businessAccount.accountClosedAt} />}
      </MobileHeader>
      <DesktopHeader>
        <DesktopHeaderTop>
          <DesktopTotalAmountWrapper>
            {isLoading && <Skeleton height="2.25rem" width="6.5rem" />}
            {!isLoading && (
              <>
                <TotalAmount>{currencyFormatter(totalBalance)}</TotalAmount>
                <Tooltip id="total-balance-info-desktop" content={tc("accounts.fundsSafeNotice")}>
                  <InfoIcon width={24} />
                </Tooltip>
              </>
            )}
          </DesktopTotalAmountWrapper>
          {canTransfer && (
            <ButtonWrapper>
              <Button
                onClick={() => navigateToCreateTransfer({ updateTransferOrigin: true })}
                size="small"
                isLoading={false}
                disabled={!isBaActive}
              >
                {t("accounts.buttonTransfer.text")}
              </Button>
            </ButtonWrapper>
          )}
        </DesktopHeaderTop>
        {businessAccount?.accountClosedAt && <AccountClosedBanner closingDate={businessAccount.accountClosedAt} />}
        {yieldInformations?.isEnabled && isAllBusinessAccount && (
          <DesktopYieldWrapper>
            <MetricItem
              title={t("ba.yield.currentPercentage.title")}
              value={`${yieldInformations.currentYieldPercentage} %`}
              text={`${t("ba.yield.currentPercentage.text")} ${DateTime.fromJSDate(
                new Date(yieldInformations.yieldConfigurationUpdatedAt),
              )
                .setLocale(lang)
                .toFormat(getDateFormat(lang, new Date(yieldInformations.yieldConfigurationUpdatedAt)))}`}
              state="default"
              tooltipInfo={t("ba.yield.currentPercentage.tooltip")}
            />
            <MetricItem
              disabled={yieldInformations.transactionCardCountNeeded > 0}
              title={t("ba.yield.currentMonth.title")}
              value={`${toEuros(yieldInformations.currentTotalYield)}`}
              text={
                yieldInformations.transactionCardCountNeeded > 0 ? (
                  <BadgeYieldTransactionNeeded>
                    <Typography
                      $variant="caption-1"
                      $color="secondary.$200"
                    >{`${yieldInformations.transactionCardCountNeeded} ${t("ba.yield.currentMonth.textDisabled")}`}</Typography>
                  </BadgeYieldTransactionNeeded>
                ) : (
                  `${t("ba.yield.currentMonth.text")}`
                )
              }
              state="default"
              tooltipInfo={t("ba.yield.currentMonth.tooltip")}
            />
            <MetricItem
              title={t("ba.yield.total.title")}
              value={`${toEuros(yieldInformations.totalEarnedYield)}`}
              text={
                yieldInformations.firstPayoutDate
                  ? `${t("ba.yield.total.text")} ${DateTime.fromJSDate(new Date(yieldInformations.firstPayoutDate)).setLocale(lang).toFormat("MMMM yyyy")}`
                  : `${t("ba.yield.total.textNoPayout")}`
              }
              state="default"
              tooltipInfo={t("ba.yield.total.tooltip")}
            />
          </DesktopYieldWrapper>
        )}
      </DesktopHeader>
      <AccountHeaderTabs />
    </HeaderWithoutGradient>
  )
}
