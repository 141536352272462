import { Tab, Tabs } from "@hero/krypton"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const TabsContainer = styled(Tabs)`
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-around;

    & button,
    a {
      flex: 1;
      text-align: center;
      justify-content: center;
      margin-right: 0;
    }
  }
`

const historyPath = "/transfers/transactions"
const beneficiariesPath = "/transfers/beneficiaries"

export const TransfersHeaderTabs = () => {
  const { t } = useDashboardTranslation()

  const location = useLocation()

  const isHistorySelected = location.pathname.startsWith(historyPath)
  const isBeneficiariesSelected = location.pathname.startsWith(beneficiariesPath)

  return (
    <TabsContainer>
      <Tab as={Link} $isSelected={isHistorySelected} to={historyPath}>
        {t("transfers.list.tabs.history")}
      </Tab>

      <Tab as={Link} $isSelected={isBeneficiariesSelected} to={beneficiariesPath}>
        {t("transfers.list.tabs.beneficiaries")}
      </Tab>
    </TabsContainer>
  )
}
