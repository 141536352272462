import { Tooltip, Typography } from "@hero/krypton"
import React, { useEffect } from "react"
import { Card, CardContent, CardHeader } from "../../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import { SelectableCard } from "../../../../../00_shared/components/SelectableCard"
import { useAmplitude } from "../../../../../00_shared/hooks/useAmplitude.hook"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useCapability } from "../../../../../Auth/auth.context"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import AccountToAccountTransferIcon from "../../00_shared/icons/account-to-account-icon.png"
import beneficiaryIcon from "../../00_shared/icons/beneficiary-transfer-icon.png"
import { useBusinessAccounts } from "../00_shared/hooks/useBusinessAccounts"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"

export const SelectTransferType: React.FC = () => {
  const { isBaActive } = useBusinessAccountContext()
  const [optionClicked, setOptionClicked] = React.useState(false)
  const { state, setState, handleNextStep } = useCreateTransferContext()
  const { t } = useCommonTranslation()
  const { t: dashboardT } = useDashboardTranslation()
  const { businessAccounts, loading: baLoading } = useBusinessAccounts()
  const { track } = useAmplitude()
  const { createTransferOrigin } = useBusinessAccountContext()

  const canExternalTransfer = useCapability("send-external-transfer", "any")
  const canInternalTransfer = useCapability("send-internal-transfer", "any")

  const handleSelection = (type: TransferType) => {
    logAmplitude(type)
    setOptionClicked(true)
    setState((prev) => ({ ...prev, selectedTransferType: type }))
  }

  const logAmplitude = (type: TransferType): void => {
    const isBeneficiary = type === TransferType.BENEFICIARY
    const isFromListAllBA = createTransferOrigin === "/accounts/transactions"
    const isFromTransfer = createTransferOrigin === "/transfers/transactions"

    if (isFromListAllBA) {
      track(
        isBeneficiary
          ? "business_account_send_a_transfer_to_a_beneficiary_from_list_all_ba_clicked"
          : "business_account_send_a_transfer_between_account_from_list_all_ba_clicked",
      )
    }
    if (isFromTransfer) {
      track(
        isBeneficiary
          ? "business_account_send_a_transfer_to_a_beneficiary_from_transfer"
          : "business_account_send_a_transfer_between_account_from_transfer",
      )
    }
  }

  useEffect(() => {
    if (optionClicked && state.selectedTransferType) {
      handleNextStep()
    }
  }, [state.selectedTransferType, optionClicked, handleNextStep])

  useEffect(() => {
    setOptionClicked(false)
  }, [])

  return (
    <Card $variant="plain">
      <CardHeader>
        <Typography $variant="title-2-bold">{t("wire.chooseWireType.content.subtitle")}</Typography>
      </CardHeader>
      <CardContent>
        <FlexContainer $direction="column" $gap="1rem">
          {canExternalTransfer && (
            <SelectableCard
              onClick={() => handleSelection(TransferType.BENEFICIARY)}
              imageSrc={beneficiaryIcon}
              title={t("wire.chooseWireType.external.title")}
              description={t("wire.chooseWireType.external.subtitle")}
              variant={!isBaActive ? "disabled" : "default"}
            />
          )}
          {canInternalTransfer && (
            <Tooltip
              id="tooltip-disabled"
              content={businessAccounts.length < 2 ? dashboardT("wire.chooseWireType.internal.disabled.tooltip") : ""}
              position="bottom-left"
            >
              <SelectableCard
                onClick={() => handleSelection(TransferType.ACCOUNT_TO_ACCOUNT)}
                imageSrc={AccountToAccountTransferIcon}
                title={t("wire.chooseWireType.internal.title")}
                description={t("wire.chooseWireType.internal.subtitle")}
                variant={!isBaActive || businessAccounts.length < 2 ? "disabled" : "default"}
                isLoading={baLoading}
              />
            </Tooltip>
          )}
        </FlexContainer>
      </CardContent>
    </Card>
  )
}
