export const getTranslationKey = ({ action, target }: { action: string; target: string }) => {
  if (action === "add-beneficiary" && target === "any") {
    return "userManagement.productStrategy.add-beneficiary.toggle"
  }
  if (action === "create-account" && target === "any") {
    return "userManagement.productStrategy.create-account.toggle"
  }

  if (action === "view-account") {
    return "userManagement.productStrategy.view-account.toggle"
  }

  if (action === "view-beneficiaries" && target === "any") {
    return "userManagement.productStrategy.view-beneficiaries.toggle"
  }

  if (action === "send-external-transfer" && target === "any") {
    return "userManagement.productStrategy.send-external-transfer.toggle"
  }
  if (action === "send-internal-transfer" && target === "any") {
    return "userManagement.productStrategy.send-internal-transfer.toggle"
  }

  if (action === "create-card" && target === "any") {
    return "userManagement.productStrategy.create-card.toggle"
  }

  return action
}
