import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { toaster } from "@hero/krypton"
import * as Sentry from "@sentry/react"
import { DateTime } from "luxon"
import { useDownloadFile } from "../../../../../00_shared/hooks/useDownloadFile"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"

const GET_MERCHANT_BANK_STATEMENT_QUERY = gql`
  query GetMerchantBankStatements($businessAccountLedgerId: String!, $page: Int!, $limit: Int!) {
    getMerchantBankStatements(pagination: { limit: $limit, page: $page }, businessAccountId: $businessAccountLedgerId) {
      ... on GetMerchantBankStatementsOutput {
        hasNext
        total
        limit
        currentPage
        bankStatements {
          id
          month
          year
        }
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

const GET_SIGNED_URL = gql`
  query GetBankStatementDownloadableLink($businessAccountLedgerId: String!, $bankStatementId: String!) {
    getBankStatementDownloadableLink(bankStatementId: $bankStatementId, businessAccountId: $businessAccountLedgerId) {
      ... on GetBankStatementDownloadableLinkOutput {
        signedUrl
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
      ... on ValidationErrors {
        errorCode
      }
    }

    getMerchantBusinessAccount(businessAccountLedgerId: $businessAccountLedgerId) {
      ... on GetMerchantBusinessAccountOutput {
        businessAccount {
          name
          isMainAccount
        }
        merchantInfo {
          juridicalName
        }
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
    }
  }
`

const PAGE_LIMIT = 8

export const useBankStatement = ({
  businessAccountLedgerId,
  page,
}: {
  businessAccountLedgerId: string | undefined
  page: number
}) => {
  const { downloadFile } = useDownloadFile()
  const { currentUser } = useAuthContext()
  const { t } = useCommonTranslation()

  const { data, refetch, loading, error } = useQuery<{
    getMerchantBankStatements: {
      hasNext: boolean
      total: number
      limit: number
      currentPage: number
      bankStatements: {
        id: string
        month: number
        year: number
      }[]
    }
  }>(GET_MERCHANT_BANK_STATEMENT_QUERY, {
    variables: {
      businessAccountLedgerId: businessAccountLedgerId,
      page: page,
      limit: PAGE_LIMIT,
    },
    skip: !businessAccountLedgerId,
    fetchPolicy: "cache-and-network",
  })

  const [getOperationJustificativePresignedUrl] = useLazyQuery<
    {
      getBankStatementDownloadableLink:
        | {
            signedUrl: string
            errorCode: undefined
            message: undefined
            detail: undefined
          }
        | {
            errorCode: string
            message: string
            detail: string
            signedUrl: undefined
          }
      getMerchantBusinessAccount:
        | {
            businessAccount: {
              name: string
              isMainAccount: boolean
            }
            merchantInfo: {
              juridicalName: string
            }
            errorCode: undefined
            message: undefined
            detail: undefined
          }
        | {
            errorCode: string
            message: string
            detail: string
            businessAccount: undefined
            merchantInfo: undefined
          }
    },
    { bankStatementId: string; businessAccountLedgerId: string }
  >(GET_SIGNED_URL)

  const handleDownload = async (bankStatementId: string, month: number, year: number) => {
    if (!businessAccountLedgerId) {
      return
    }
    const { data, error } = await getOperationJustificativePresignedUrl({
      variables: {
        businessAccountLedgerId,
        bankStatementId,
      },
    })

    if (data?.getBankStatementDownloadableLink.errorCode || data?.getMerchantBusinessAccount.errorCode) {
      toaster.error(t("ba.bankStatement.download.error"))
      Sentry.captureException(`An error occurred while downloading the file bank statement : ${error} , ${data} `)
    }

    if (data?.getBankStatementDownloadableLink.signedUrl && data?.getMerchantBusinessAccount.businessAccount) {
      const accountName = data.getMerchantBusinessAccount.businessAccount.isMainAccount
        ? t("onboarding.businessAccount.name")
        : data.getMerchantBusinessAccount.businessAccount.name

      const name = `${data.getMerchantBusinessAccount.merchantInfo.juridicalName} ${accountName} ${month.toString()}${year.toString()}.pdf`
      await downloadFile(data.getBankStatementDownloadableLink.signedUrl, name)
      toaster.success(t("ba.bankStatement.download.success"))
    }

    if (error) {
      toaster.error(t("ba.bankStatement.download.error"))
      Sentry.captureException(`An error occurred while downloading the file bank statement : ${error} `)
    }
  }

  const mapBankStatementDate = (bankStatement: { id: string; month: number; year: number }) => {
    return {
      id: bankStatement.id,
      date: `${DateTime.fromObject({ month: bankStatement.month }).setLocale(currentUser.locale).toFormat("MMMM")} ${bankStatement.year}`,
      month: bankStatement.month,
      year: bankStatement.year,
    }
  }

  return {
    loading,
    bankStatement: data?.getMerchantBankStatements.bankStatements?.map(mapBankStatementDate) || [],
    totalPages: data?.getMerchantBankStatements.total || 1,
    handleDownload,
    error,
    refetch,
  }
}
