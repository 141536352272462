import { gql } from "@apollo/client"

export const UPDATE_CAPABILITY = gql`
  mutation updateCapability($capabilities: [UpdateCapabilityInput!]!) {
    updateCapability(capabilities: $capabilities) {
      ... on UpdateCapabilityOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

export const LIST_CAPABILITIES = gql`
  query listCapabilities($actor: String!) {
    listCapabilities(actor: $actor) {
      ... on CapabilityList {
        capabilities {
          action
          target
          isAllowed
          friendlyTargetName
        }
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`
