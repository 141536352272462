export enum ApRegulatedStatusEnum {
  OPENED = "opened",
  OPENING_CANCELLED = "opening_cancelled",
  PARTIALLY_REPAID = "partially_repaid",
  TOTALLY_REPAID = "totally_repaid",
}

export enum ApHistoryTypeEnum {
  INIT = "INIT",
  UNLOCKING_FUNDS = "UNLOCKING_FUNDS",
  TAKE_FEE = "TAKE_FEE",
  TRANSFER_FUNDS_TO_BA = "TRANSFER_FUNDS_TO_BA",
  DEBT_REPAYMENT = "DEBT_REPAYMENT",
  DEBT_TOTALLY_REPAID = "DEBT_TOTALLY_REPAID",
  CANCELED = "CANCELED",
}
