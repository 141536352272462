import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { IssuingCard } from "../../00_shared/business/IssuingCard"
import { IssuingTransaction } from "../../00_shared/business/IssuingTransaction"

const LIST_ISSUING_CARDS = gql`
  query listIssuingCards($id: String!) {
    listCards(pagination: { limit: 1, page: 1 }, filters: { ids: [$id] }) {
      ... on ListIssuedCardsResult {
        cards {
          id
          merchantId
          businessAccountId
          issuanceType
          status
          expiration
          lastCardDigits
          displayLastCardDigits
          allowContactless
          allowEcommerce
          allowOtherCurrency
          cardholderName
          monthlySpendingLimitAmount
          consumedMonthlySpendingLimitAmount
          monthlySpendingLimitAmountEuros
          consumedMonthlySpendingLimitAmountEuros
          dailySpendingLimitAmount
          perTransactionLimitAmount
          paymentAccountName
          pending3dsChallengeId
          formattedOrderedDate
          formattedProductionDate
          formattedReceptionDate
          isDeliveryLate
          notReceivedDeclarationDate
        }
        total
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

const GET_ISSUING_CARD_TRANSACTION = gql`
  query getIssuingCardTransaction($cardId: String!, $transactionId: String!) {
    getIssuingCardTransaction(cardId: $cardId, transactionId: $transactionId) {
      ... on GetIssuingCardTransactionOutput {
        id
        businessId
        amountEuros
        label
        operationType
        createdAt
        createdAtFormatted
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const useIssuingCardTransaction = (cardId?: string, transactionId?: string) => {
  const {
    data: transaction,
    loading: loadingTransaction,
    error: errorTransaction,
  } = useHeroQuery<IssuingTransaction>({
    gqlQuerySchema: GET_ISSUING_CARD_TRANSACTION,
    fetchPolicy: "cache-and-network",
    variables: {
      cardId: cardId,
      transactionId: transactionId,
    },
    skip: !cardId || !transactionId,
  })

  const {
    data: cardsResult,
    loading: loadingCard,
    error: errorCard,
  } = useHeroQuery<{ cards: IssuingCard[] }>({
    gqlQuerySchema: LIST_ISSUING_CARDS,
    fetchPolicy: "cache-and-network",
    variables: {
      id: cardId,
    },
    skip: !cardId,
  })

  const card = cardsResult?.cards?.[0] || null

  return { card, loadingCard, errorCard, transaction, loadingTransaction, errorTransaction }
}
