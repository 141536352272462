export const forEnumOrElse = <E extends string | number, ReturnType>(
  enumValue: E,
  callbacks: Partial<Record<E, () => ReturnType | never>>,
  fallback: () => ReturnType,
) => {
  const cb = callbacks[enumValue]
  if (!cb) {
    return fallback()
  }
  return cb()
}

export const forEnum = <EnumValue extends string | number, Callbacks extends Record<EnumValue, () => any>>(
  enumValue: EnumValue,
  callbacks: Callbacks,
): ReturnType<Callbacks[typeof enumValue]> => {
  const cb = callbacks[enumValue]
  if (!cb) {
    // ? should never happen, ts should catch it before
    throw new Error("UNKNOWN_ENUM_VALUE")
  }
  return cb()
}
