import { Toggle, Typography } from "@hero/krypton"
import { useDashboardTranslation } from "../../../01_technical/translations"
import styled from "styled-components"
import { Capability } from "../00_shared/Capability"

const SRPBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProductCapabilities = ({
  capabilities,
  onChange,
}: {
  capabilities: Capability[]
  onChange: (capability: Capability) => void
}) => {
  const { t } = useDashboardTranslation()

  return (
    <div>
      {capabilities.map((cap, i) => (
        <>
          <SRPBlock key={cap.target} style={{ marginBottom: "1em" }}>
            <Typography $variant="body-4-medium">
              {t(cap.translationKey, { target: cap.friendlyTargetName ?? cap.target })}
            </Typography>
            <Toggle
              checked={cap.isAllowed}
              aria-labelledby="capability"
              $variant="primary"
              onClick={() => {
                onChange({
                  action: cap.action,
                  target: cap.target,
                  isAllowed: !cap.isAllowed,
                  translationKey: cap.translationKey,
                })
              }}
            />
          </SRPBlock>
        </>
      ))}
    </div>
  )
}
