import { Navigate, Outlet } from "react-router-dom"
import { BOV2RouteProtector } from "../../../../App.router"
import { TransactionScreen } from "./transaction.screen"
import { GenericErrorBoundary } from "../../../../00_shared/components/GenericErrorBoundary"

export const transactionRoute = {
  path: "transactions/*",
  element: <Outlet />,
  children: [
    { index: true, element: <Navigate to="cockpit" /> },
    {
      path: ":id",
      element: (
        <BOV2RouteProtector>
          <TransactionScreen />
        </BOV2RouteProtector>
      ),
    },
  ],
  errorElement: <GenericErrorBoundary />,
}
