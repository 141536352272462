import { DateTime } from "luxon"
import { CommonTFunction } from "../../../../01_technical/translations"
import { UserLocale } from "../../../../business/enums/User.enum"
import { LedgerMovementFlow, OperationType, YIELD_PAYOUT_UNIQUE_LABEL } from "../../00_shared/business"
import { formatOperationStatus } from "../../00_shared/utils"
import { OperationDetails } from "../00_shared/hooks/useOperationDetails"
import { centsToEuros, i18nCurrencyFormatter } from "../../../../00_shared/utils/currency.converter"

export const mapMethodToFlows = (method: string): LedgerMovementFlow[] => {
  switch (method) {
    case "card":
      return [LedgerMovementFlow.ISSUING_REFUND, LedgerMovementFlow.ISSUING_RESERVATION]
    default:
      return []
  }
}

export const mapTypeToFlows = (type: string): LedgerMovementFlow[] => {
  switch (type) {
    case "incoming":
      return [LedgerMovementFlow.ISSUING_REFUND]
    case "outgoing":
      return [LedgerMovementFlow.ISSUING_RESERVATION]
    default:
      return []
  }
}

export const mapTypeToOperationTypes = (type: string): OperationType[] => {
  switch (type) {
    case "incoming":
      return [OperationType.SEPA_CREDIT_TRANSFER_IN, OperationType.ACCOUNT_TO_ACCOUNT_IN]
    case "outgoing":
      return [OperationType.ACCOUNT_TO_ACCOUNT_OUT, OperationType.SEPA_CREDIT_TRANSFER_OUT]
    default:
      return []
  }
}

export const mapMethodToOperationTypes = (method: string): OperationType[] => {
  switch (method) {
    case "transfer":
      return [
        OperationType.SEPA_CREDIT_TRANSFER_IN,
        OperationType.SEPA_CREDIT_TRANSFER_OUT,
        OperationType.ACCOUNT_TO_ACCOUNT_IN,
        OperationType.ACCOUNT_TO_ACCOUNT_OUT,
      ]
    case "debit":
      return [] // Add corresponding OperationTypes for "debit" if they exist
    default:
      return []
  }
}

interface OperationItem {
  label: string
  value: string
}

export interface FormattedDetails {
  operationItems: OperationItem[]
  createdAt: string
  status:
    | {
        label: string
        value: string | undefined
      }[]
    | undefined
  label: string
  pageTitle: string
  amount: string
  justificativePath?: string
  note?: string
  operationType: OperationType
}

export const formatDetails = (data: OperationDetails, t: CommonTFunction, local: UserLocale): FormattedDetails => {
  const operationItems = [
    {
      label: `${t("operation.details.transaction")}`,
      value: getLabel(data),
    },

    { label: `${t("operation.details.method")}`, value: `${t("operation.details.transfer")}` },
  ]

  if (data.operationType === OperationType.FEES_OUT) {
    operationItems.push({
      label: `${t("operation.details.fees.amountWithoutTVA")}`,
      value: i18nCurrencyFormatter(centsToEuros(data.amountWithoutVat || 0), "fr"),
    })
    operationItems.push({
      label: `${t("operation.details.fees.amountWithTVA")}`,
      value: i18nCurrencyFormatter(centsToEuros(data.vatAmount || 0), "fr"),
    })
  } else if (data.reference?.trim() !== YIELD_PAYOUT_UNIQUE_LABEL) {
    operationItems.push({
      label: `${t("operation.details.reference")}`,
      value: data.reference || "-",
    })
  }

  return {
    operationItems,
    createdAt: DateTime.fromJSDate(new Date(data.createdAt)).setLocale(local).toLocaleString(DateTime.DATE_MED),
    status: formatOperationStatus(data.status, t, local),
    label: getLabel(data),
    pageTitle: t("account.operation.details.title"),
    amount: new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.amount / 100),
    justificativePath: data.justificativePath,
    note: data.note,
    operationType: data.operationType,
  }
}

const getLabel = (data: OperationDetails) => {
  if (
    data.operationType === OperationType.ACCOUNT_TO_ACCOUNT_OUT ||
    data.operationType === OperationType.SEPA_CREDIT_TRANSFER_OUT ||
    data.operationType === OperationType.FEES_OUT
  ) {
    return data.creditorName
  }

  return data.debtorName
}
