import { gql } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { IssuingCard } from "../00_shared/business/IssuingCard"
import { IssuingTransaction } from "../00_shared/business/IssuingTransaction"

interface ListIssuingCardRecentTransactionsPaginationRequest {
  limit?: number
  page?: number
}

interface ListIssuingCardRecentTransactionsPaginationResult {
  limit: number
  total: number
  hasNext: boolean
  currentPage: number
}

interface ListIssuingCardRecentTransactionsRequest {
  pagination: ListIssuingCardRecentTransactionsPaginationRequest
}

const GET_MERCHANT_OPERATIONS = gql`
  query listIssuingCards($id: String!) {
    listCards(pagination: { limit: 1, page: 1 }, filters: { ids: [$id] }) {
      ... on ListIssuedCardsResult {
        cards {
          id
          merchantId
          businessAccountId
          issuanceType
          status
          expiration
          lastCardDigits
          displayLastCardDigits
          allowContactless
          allowEcommerce
          allowOtherCurrency
          cardholderName
          monthlySpendingLimitAmount
          consumedMonthlySpendingLimitAmount
          monthlySpendingLimitAmountEuros
          consumedMonthlySpendingLimitAmountEuros
          dailySpendingLimitAmount
          perTransactionLimitAmount
          paymentAccountName
          pending3dsChallengeId
          formattedOrderedDate
          formattedProductionDate
          formattedReceptionDate
          isDeliveryLate
          notReceivedDeclarationDate
        }
        total
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

const GET_CARD_RECENT_TRANSACTIONS = gql`
  query listIssuingCardRecentTransactions($cardId: String!, $pagination: ListRecentTransactionsPagination!) {
    listIssuingCardRecentTransactions(cardId: $cardId, pagination: $pagination) {
      ... on ListIssuingCardRecentTransactionsOutput {
        operations {
          id
          businessId
          amountEuros
          label
          operationType
          createdAt
          createdAtFormatted
        }
        pagination {
          total
          currentPage
          limit
          hasNext
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

const defaultPagination = { page: 1, limit: 10 }

export const useIssuingCardDetailsHook = (
  id?: string,
  pagination: ListIssuingCardRecentTransactionsPaginationRequest = defaultPagination,
) => {
  const location = useLocation()
  const { cardId } = useParams<{ cardId: string }>()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const [request, setRequest] = useState<ListIssuingCardRecentTransactionsRequest>({
    pagination: pagination,
  })

  const card = useHeroQuery<{ cards: IssuingCard[] }>({
    gqlQuerySchema: GET_MERCHANT_OPERATIONS,
    fetchPolicy: "cache-and-network",
    variables: {
      id: cardId,
    },
    skip: !cardId,
  })

  const transactions = useHeroQuery<{
    operations: IssuingTransaction[]
    pagination: ListIssuingCardRecentTransactionsPaginationResult
  }>({
    gqlQuerySchema: GET_CARD_RECENT_TRANSACTIONS,
    fetchPolicy: "cache-and-network",
    variables: {
      cardId: cardId,
      pagination: request.pagination,
    },
    skip: !cardId,
  })

  useEffect(() => {
    setRequest({
      pagination: {
        page: Number(searchParams.get("page")) || defaultPagination.page,
        limit: defaultPagination.limit,
      },
    })
  }, [searchParams])

  return {
    card: card.data?.cards?.[0] || undefined,
    transactions: transactions.data?.operations ? transactions.data.operations : [],
    pagination: transactions.data?.pagination || { limit: 10, total: 0, hasNext: false, currentPage: 1 },
    loading: card.loading || transactions.loading,
    error: card.error || transactions.error,
    request,
    setRequest,
    refetch: () => {
      card.refetch()
      transactions.refetch()
    },
  }
}
