import { LockIcon, Separator, Typography } from "@hero/krypton"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { DashboardSectionHeaderIconWrapper as HeaderIconWrapper } from "../../../00_shared/components/krypton2/DashboardSection"
import { Spacer } from "../../../00_shared/components/Spacer"
import styled from "styled-components"

const ContextPanelWrapper = styled.div`
  flex: 0 0 25%;
  padding: 0 1.5rem;
`

const UnderlinedLink = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`

const ContextPanelHeaderIcon = styled(HeaderIconWrapper)`
  display: inline-block;
  margin-right: 0.5rem;
  text-align: center;
`

const ContextPanelTitle = styled(Typography)`
  margin-bottom: 0.5rem;
`

export const ContextPanel = () => {
  const { t } = useDashboardTranslation()

  return (
    <ContextPanelWrapper>
      <ContextPanelTitle $variant="body-1-medium">
        <ContextPanelHeaderIcon as="span">
          <LockIcon />
        </ContextPanelHeaderIcon>
        {t("userManagement.contextPanel.accessControl.header")}
      </ContextPanelTitle>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.accessControl.description")}</Typography>
      <Spacer $height={"1.625rem"} />
      <ContextPanelTitle $variant="body-1-medium">
        {t("userManagement.contextPanel.collaborator.header")}
      </ContextPanelTitle>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.collaborator.description")}</Typography>
      <Spacer $height={"1.625rem"} />
      <ContextPanelTitle $variant="body-1-medium">
        {t("userManagement.contextPanel.adminRole.header")}
      </ContextPanelTitle>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.adminRole.description")}</Typography>
      <Spacer $height={"1.625rem"} />
      <ContextPanelTitle $variant="body-1-medium">
        {t("userManagement.contextPanel.ownerRole.header")}
      </ContextPanelTitle>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.ownerRole.description")}</Typography>
      <Spacer $height={"1.625rem"} />
      <Separator />
      <Spacer $height={"1.625rem"} />
      <UnderlinedLink $variant="caption-2" as="a" href="mailto:support@hero.fr">
        {t("userManagement.contextPanel.moreInfo.linkText")}
      </UnderlinedLink>{" "}
      <Typography as="span" $variant="caption-2">
        {t("userManagement.contextPanel.moreInfo.linkTextSuffix")}
      </Typography>
      <Spacer />
    </ContextPanelWrapper>
  )
}
