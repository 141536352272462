import { useState } from "react"
import { toaster } from "@hero/krypton"
import { CommonTFunction, useCommonTranslation } from "../../../../../01_technical/translations"
import { exportCsv } from "../../../../../00_shared/utils/generateExportCSV"
import { BankStatementExportDataLine, useLazyGetBankStatementExportData } from "./getBankStatementExportData.hook"
import { TFunction } from "i18next"
import { centsToEuros } from "../../../../../00_shared/utils/currency.converter"

const ExportErrorMapper = (t: TFunction): Record<string, string> => ({
  BAD_DATE_FORMAT: t("ba.bankStatement.export.errors.badDateFormat"),
  START_DATE_AFTER_END_DATE: t("ba.bankStatement.export.errors.startDateAfterEndDate"),
})

export const useBankStatementExport = () => {
  const getBankStatementExportData = useLazyGetBankStatementExportData()
  const { t, unsafeT } = useCommonTranslation()

  const [exporting, setExporting] = useState(false)

  const exportBankStatement = async (
    ledgerIds: string[],
    startDate: Date,
    endDate: Date,
    documentFormat: "csv" | "xsl",
  ) => {
    try {
      setExporting(true)

      const bankStatementExportData = await getBankStatementExportData({
        ledgerIds,
        startDate,
        endDate,
      })
      const formatedData = formatData(bankStatementExportData, t)
      const sheetName = getSheetName(startDate, endDate)

      if (documentFormat === "csv") {
        exportCsv({ fileName: sheetName, data: formatedData })
        toaster.success(t("ba.bankStatement.export.success"))
      }
    } catch (error: any) {
      const errorMessage = getErrorMessage(unsafeT, error)
      toaster.error(errorMessage)
      console.error(error)
    } finally {
      setExporting(false)
    }
  }
  return {
    exportBankStatement,
    exporting,
  }
}

const getSheetName = (startDate: Date, endDate: Date) => {
  return "HERO - " + startDate.toISOString().split("T")[0] + " - " + endDate.toISOString().split("T")[0]
}

const columnKeys = [
  "id",
  "accountName",
  "iban",
  "requestDate",
  "type",
  "counterparty",
  "debit",
  "credit",
  "currency",
  "externalReference",
  "internalNote",
  "orderingParty",
  "hasJustificative",
  "lastCardDigits",
]

const createEmptyLine = (t: CommonTFunction) => {
  const emptyLine: Record<string, null> = {}

  columnKeys.forEach((key) => {
    emptyLine[t(`ba.bankStatement.column.${key}` as keyof typeof t)] = null
  })

  return emptyLine
}

const convertAmountToEuros = (bankStatementExportData: BankStatementExportDataLine[]) =>
  bankStatementExportData.map((line) => ({
    ...line,
    credit: line.currency === "EUR" && line.credit ? centsToEuros(line.credit) : line.credit,
    debit: line.currency === "EUR" && line.debit ? centsToEuros(line.debit) : line.debit,
  }))

const formatRequestDate = (bankStatementExportData: BankStatementExportDataLine[]) =>
  bankStatementExportData.map((line) => ({
    ...line,
    requestDate: new Date(line.requestDate).toLocaleDateString("fr-FR"),
  }))

const formatData = (data: BankStatementExportDataLine[], t: CommonTFunction) => {
  if (data.length === 0) {
    return [createEmptyLine(t)]
  }

  const dataInEuro = convertAmountToEuros(data)

  const dataWithFormattedRequestDate = formatRequestDate(dataInEuro)

  return dataWithFormattedRequestDate.map((bankStatementLine) => {
    const line: Record<string, any> = {}

    columnKeys.forEach((key) => {
      line[t(`ba.bankStatement.column.${key}` as keyof typeof t)] =
        bankStatementLine[key as keyof BankStatementExportDataLine]
    })

    return line
  })
}

const getErrorMessage = (t: TFunction, error: any) => {
  return ExportErrorMapper(t)[error.message] || t("ba.bankStatement.export.error")
}
