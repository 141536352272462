import { Typography } from "@hero/krypton"
import styled from "styled-components"

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableHead = styled.thead`
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$100};
`

export const TableRow = styled.tr<{ onClick?: () => void | undefined }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$100};
  border-top: 1px solid ${({ theme }) => theme.colors.grey.$100};

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    border-top: none;
  }

  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "default")};
`

const TH = styled.th`
  padding-bottom: 0.5rem;
`

export const TableHeaderCell = ({
  children,
  align,
}: {
  children: React.ReactNode
  align?: "left" | "right" | "center"
}) => {
  return (
    <TH>
      <Typography
        $variant="caption-2"
        $color="grey.$400"
        style={{
          textAlign: align || "left",
        }}
      >
        {children}
      </Typography>
    </TH>
  )
}

export const TableBody = styled.tbody``

export const TableCell = styled.td`
  padding-top: 1rem;
  padding-bottom: 1rem;
  vertical-align: middle;
`

export const TableGroup = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return <>{children}</>
}
