import { ErrorBlock, Separator, Typography } from "@hero/krypton"
import styled from "styled-components"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../00_shared/components/Sheet"
import { Spacer } from "../../../00_shared/components/Spacer"
import { centsToEuros, i18nCurrencyFormatter } from "../../../00_shared/utils/currency.converter"
import {
  DateStyle,
  isDifferentDay,
  toInternationalDate,
  toInternationalDatetime,
} from "../../../00_shared/utils/date.converter"
import { formatAsPercentage } from "../../../00_shared/utils/percentage.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { StatusBadge } from "./StatusBadge"
import { useGetAcceleratedPayoutDetails } from "../hooks/getAcceleratedPayoutDetails"
import { ApHistoryTypeEnum, ApRegulatedStatusEnum } from "../../AcceleratedPayout/00_shared/types/types"
import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import { Timeline } from "../../../00_shared/components/krypton2/Timeline"

export const AcceleratedPayoutDetails = ({
  close,
  detailsContext,
}: {
  detailsContext: { modalOpen: boolean; aprId: string }
  close: () => void
}) => {
  const { t, lang } = useDashboardTranslation()
  const getTranslationForHistoryType = useGetTranslationForHistoryType()

  const { data: details, error, loading } = useGetAcceleratedPayoutDetails({ aprId: detailsContext.aprId })

  let history: {
    type: ApHistoryTypeEnum | "WILL_BE_REPAID"
    date?: string
    amount?: number
    isDone?: boolean
  }[] = details?.history || []

  if (details?.status !== ApRegulatedStatusEnum.TOTALLY_REPAID && history.length > 0) {
    history = [
      ...history,
      {
        type: "WILL_BE_REPAID",
        isDone: false,
      },
    ]
  }

  const TTC = t("apRegulated.dashboard.apDetails.TTCAbbreviation")
  const HT = t("apRegulated.dashboard.apDetails.HTAbbreviation")

  return (
    <Sheet
      open={Boolean(detailsContext.modalOpen)}
      onOpenChange={(open) => {
        if (!open) {
          close()
        }
      }}
    >
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle>{t("apRegulated.dashboard.apDetails.title")}</SheetTitle>
        </SheetHeader>

        {error && <ErrorBlock $margin="0">{error.translatedMessage}</ErrorBlock>}

        {loading && <CenteredLoading />}

        {details && (
          <>
            <Header>
              <div>
                <Typography $variant="title-3-inter-bold">
                  {i18nCurrencyFormatter(centsToEuros(details.financedAmount), lang)}
                </Typography>
                <Typography $variant="body-4-regular">
                  {toInternationalDate({
                    date: details.periodStartAt,
                    language: lang,
                    style: DateStyle.TEXTUAL,
                  })}
                  {isDifferentDay(details.periodStartAt, details.periodEndAt) && (
                    <>
                      {" "}
                      -{" "}
                      {toInternationalDate({
                        date: details.periodEndAt,
                        language: lang,
                        style: DateStyle.TEXTUAL,
                      })}
                    </>
                  )}
                </Typography>
              </div>
              <div>
                <StatusBadge status={details.status}></StatusBadge>
              </div>
            </Header>

            <Spacer $height="2.5rem" />

            <Timeline
              timeline={history.map((history) => {
                const label = getTranslationForHistoryType(history.type)

                return {
                  title: label(history.amount) || history.type,
                  subtitle:
                    history.date &&
                    toInternationalDatetime({
                      date: history.date,
                      language: lang,
                      style: DateStyle.TEXTUAL,
                    }),
                  isDone: history.isDone !== undefined ? history.isDone : true,
                }
              })}
            />

            <Spacer $height="2.5rem" />

            <Separator />

            <Spacer $height="2rem" />

            <Typography $variant="body-4-medium">{t("apRegulated.dashboard.apDetails.details")}</Typography>

            <Spacer $height=".5rem" />

            <Details>
              <DetailRow>
                <DetailLabel>{t("apRegulated.dashboard.apDetails.periodRevenues")}</DetailLabel>
                <DetailValue>{i18nCurrencyFormatter(centsToEuros(details.periodRevenue), lang)}</DetailValue>
              </DetailRow>

              <DetailRow>
                <DetailLabel>{t("apRegulated.dashboard.apDetails.financingRate")}</DetailLabel>
                <DetailValue>{formatAsPercentage(details.financingRate)}</DetailValue>
              </DetailRow>

              <DetailRow>
                <DetailLabel>{t("apRegulated.dashboard.apDetails.feeRate")}</DetailLabel>
                <DetailValue>{formatAsPercentage(details.feeRate)}</DetailValue>
              </DetailRow>

              <DetailRow>
                <DetailLabel>{t("apRegulated.dashboard.apDetails.feeAmount")}</DetailLabel>
                <DetailValue>
                  {details.vatAmount === 0 && `${i18nCurrencyFormatter(centsToEuros(details.feeAmount), lang)} ${TTC}`}
                  {details.vatAmount !== 0 &&
                    `${i18nCurrencyFormatter(centsToEuros(details.feeAmount), lang)} ${HT} (${i18nCurrencyFormatter(centsToEuros(details.feeAmount + details.vatAmount), lang)} ${TTC})`}
                </DetailValue>
              </DetailRow>
            </Details>
          </>
        )}
      </SheetContent>
    </Sheet>
  )
}

const useGetTranslationForHistoryType = () => {
  const { t, lang } = useDashboardTranslation()

  const formatAmount = (amount?: number) => (amount ? i18nCurrencyFormatter(centsToEuros(amount), lang) : "NO_AMOUNT")

  return (type: ApHistoryTypeEnum | "WILL_BE_REPAID"): ((amount?: number) => string | null) => {
    if (type === ApHistoryTypeEnum.INIT) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.init", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.UNLOCKING_FUNDS) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.unlockingFunds", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.TAKE_FEE) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.takeFee", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.TRANSFER_FUNDS_TO_BA) {
      return (amount) =>
        t("apRegulated.dashboard.apDetails.history.transferFundsToBa", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.DEBT_REPAYMENT) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.debtRepayment", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.DEBT_TOTALLY_REPAID) {
      return (amount) =>
        t("apRegulated.dashboard.apDetails.history.debtTotallyRepaid", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.CANCELED) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.canceled", { amount: formatAmount(amount) })
    }

    if (type === "WILL_BE_REPAID") {
      return () => t("apRegulated.dashboard.apDetails.history.willBeRepaid")
    }

    return () => null
  }
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailLabel = styled(Typography).attrs({ $variant: "body-4-regular" })`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-right: 1rem;
`

const DetailValue = styled(Typography).attrs({ $variant: "body-4-medium" })`
  color: ${({ theme }) => theme.colors.grey.$600};
`
