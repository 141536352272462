import { KryptonProvider, Spinner } from "@hero/krypton"
import { Suspense } from "react"

import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { appRoutes } from "./App.router"
import IntercomProvider from "./IntercomProvider"
import { useEffectOnce } from "./00_shared/hooks/useEffectOnce.hook"
import { initAwdRum } from "./00_shared/libs/aws-rum"

export const App = () => {
  const browserRouter = createBrowserRouter(appRoutes)

  useEffectOnce(() => {
    initAwdRum()
  })

  return (
    <IntercomProvider>
      <KryptonProvider>
        <Suspense fallback={<Spinner />}>
          <RouterProvider router={browserRouter} />
        </Suspense>
      </KryptonProvider>
    </IntercomProvider>
  )
}
