import { PermIdentityIcon, Typography } from "@hero/krypton"
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../../00_shared/components/krypton2/Table"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"
import {
  DashboardSection,
  DashboardSectionHeaderIconWrapper as HeaderIconWrapper,
} from "../../../00_shared/components/krypton2/DashboardSection"
import styled from "styled-components"
import { forEnumOrElse } from "../../../00_shared/utils/enum"
import { fullname } from "../00_shared/utils"
import { User } from "../00_shared/User"
import { UserStatusBadge } from "./UserManagementUserStatusBadge"
import { InvitationButton } from "./UserManagementInvitationButton"

const Block = styled.section`
  display: flex;
  gap: 0.5rem;
`

const MembersListWrapper = styled.div`
  flex: 0 0 75%;
`

const UserInfoWrapper = styled.div`
  padding: 1rem 0;
`

const bySearchStringFilter =
  (searchString: string) =>
  ({ firstname, lastname, email }: Pick<User, "email" | "firstname" | "lastname">) => {
    const lowerCaseSearchString = (searchString?.toLowerCase() ?? "").trim()
    return (
      firstname.toLowerCase().includes(lowerCaseSearchString) ||
      lastname.toLowerCase().includes(lowerCaseSearchString) ||
      email.toLowerCase().includes(lowerCaseSearchString)
    )
  }

const byNameSorter = (a: Pick<User, "firstname" | "lastname">, b: Pick<User, "firstname" | "lastname">) => {
  const nameA = fullname(a) ?? ""
  const nameB = fullname(b) ?? ""
  return nameA.localeCompare(nameB)
}

const UserRole = ({ role, isLegalRepresentative }: Pick<User, "role" | "isLegalRepresentative">) => {
  const { t } = useDashboardTranslation()

  return forEnumOrElse(
    role,
    {
      OWNER: () => {
        if (isLegalRepresentative) {
          return <Typography $variant="caption-2">{t("userManagement.userRole.owner_and_legalrep")}</Typography>
        }
        return <Typography $variant="caption-2">{t("userManagement.userRole.owner")}</Typography>
      },
      MEMBER: () => <Typography $variant="caption-2">{t("userManagement.userRole.member")}</Typography>,
    },
    // eslint-disable-next-line i18next/no-literal-string
    () => <div>unknown role</div>,
  )
}

const UserInfo = ({ user }: { user: Pick<User, "firstname" | "lastname" | "email" | "status"> }) => {
  return (
    <UserInfoWrapper>
      {["SUSPENDED", "INACTIVE"].includes(user.status) ? (
        <Typography $variant="body-4-medium" $color="grey.$400">
          <span style={{ textDecoration: "line-through" }}>{fullname(user)}</span>
        </Typography>
      ) : (
        <Typography $variant="body-4-medium">{fullname(user)}</Typography>
      )}

      {["SUSPENDED", "INACTIVE"].includes(user.status) ? (
        <Typography $variant="caption-2" $color="grey.$400" style={{ textDecoration: "line-through" }}>
          {user.email}
        </Typography>
      ) : (
        <Typography $variant="caption-2" $color="grey.$400">
          <span>{user.email}</span>
        </Typography>
      )}
    </UserInfoWrapper>
  )
}

export const MembersList = ({
  users,
  searchString,
  seeMore,
  editUser,
}: {
  users: User[]
  searchString: string
  seeMore: (user: User) => void
  editUser: (user: User) => void
}) => {
  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()

  return (
    <MembersListWrapper>
      <DashboardSection>
        <Block>
          <HeaderIconWrapper>
            <PermIdentityIcon />
          </HeaderIconWrapper>
          <Typography $variant="body-3-medium" $color="grey.$600">
            All members
          </Typography>
          <Typography $variant="body-3-medium" $color="grey.$400">
            {users.length}
          </Typography>
        </Block>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography $variant="caption-2">{t("userManagement.list.header.name")}</Typography>
              </TableCell>
              <TableCell>
                <Typography $variant="caption-2">{t("userManagement.list.header.status")}</Typography>
              </TableCell>
              <TableCell>
                <Typography $variant="caption-2">{t("userManagement.list.header.role")}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter(bySearchStringFilter(searchString))
              .sort(byNameSorter)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <UserInfo user={user} />
                  </TableCell>

                  <TableCell>
                    <UserStatusBadge status={user.status} hasPendingInvitation={user.hasPendingInvitation} />
                  </TableCell>

                  <TableCell>
                    <UserRole role={user.role} isLegalRepresentative={user.isLegalRepresentative}></UserRole>
                  </TableCell>

                  {currentUser.role === "owner" &&
                    (user.hasPendingInvitation ? (
                      <TableCell>
                        <InvitationButton user={user} />
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    ))}

                  <TableCell>
                    {currentUser.role === "owner" && !user.isLegalRepresentative ? (
                      <Typography
                        onClick={() => editUser(user)}
                        style={{ textAlign: "right", cursor: "pointer" }}
                        $variant="body-4-medium"
                      >
                        {t("userManagement.row.edit")}
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() => seeMore(user)}
                        style={{ textAlign: "right", cursor: "pointer" }}
                        $variant="body-4-medium"
                      >
                        {t("userManagement.row.seeMore")}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DashboardSection>
    </MembersListWrapper>
  )
}
