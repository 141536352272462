import { useMemo } from "react"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { gql } from "@apollo/client"
import { ApRegulatedStatusEnum } from "../../AcceleratedPayout/00_shared/types/types"

export interface ApRegulatedItem {
  id: string
  periodStartAt: string
  periodEndAt: string
  status: ApRegulatedStatusEnum
  financedAmount: number
  beneficiaryLabel: string
  marketplace: {
    id: string
    name: string
    logoUrl: string | null
  }
  group: {
    id: string
    label: string
  }
}

interface Group {
  id: string
  label: string
}

const getGroups = (data: ApRegulatedItem[]): Group[] => {
  return data.reduce<Group[]>((acc, item) => {
    if (acc.some((group) => group.id === item.group.id)) {
      return acc
    }

    return [...acc, item.group]
  }, [])
}

// memoized getGroups
export const useGetMemoizedGroups = (data: ApRegulatedItem[] | null): Group[] => {
  return useMemo(() => {
    if (!data) {
      return []
    }
    return getGroups(data)
  }, [data])
}

const data: ApRegulatedItem[] = Array.from({ length: 9 }).map((_, index) => {
  const startDate = new Date(2024, 0, index + 1)

  const financedAmount = Math.floor(Math.random() * 100000) + 250000

  let status
  if (index < 3) {
    status = ApRegulatedStatusEnum.OPENED
  } else if (index < 6) {
    status = ApRegulatedStatusEnum.OPENING_CANCELLED
  } else {
    status = ApRegulatedStatusEnum.TOTALLY_REPAID
  }

  const weekNumber = Math.floor(index / 6) + 1

  const periodEndAt =
    index !== 2 && index !== 4 && index !== 5
      ? startDate.toISOString()
      : new Date(startDate.getTime() + 24 * 60 * 60 * 1000).toISOString()

  return {
    id: `ap-regulated-${index}`,
    periodStartAt: startDate.toISOString(),
    periodEndAt,
    status,
    financedAmount,
    beneficiaryLabel: `Beneficiary ${index + 1}`,
    marketplace: {
      id: "marketplace-1",
      name: "Amazon",
      logoUrl: "https://upload.wikimedia.org/wikipedia/commons/d/de/Amazon_icon.png",
    },
    group: {
      id: `week-${weekNumber}`,
      label: `Week ${weekNumber} of January 2024`,
    },
  }
})

const query = gql`
  query getAcceleratedPayoutList(
    $marketplaceIds: [String!]
    $minAmount: Int
    $maxAmount: Int
    $fromDate: String
    $toDate: String
    $pageSize: Int!
    $pageNumber: Int!
  ) {
    getAcceleratedPayoutList(
      marketplaceIds: $marketplaceIds
      minAmount: $minAmount
      maxAmount: $maxAmount
      fromDate: $fromDate
      toDate: $toDate
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      ... on GetApListOutput {
        apRegulatedList {
          id
          periodStartAt
          periodEndAt
          status
          financedAmount
          beneficiaryLabel
          marketplace {
            id
            name
            logoUrl
          }
          group {
            id
            label
          }
        }
        apRegulatedListCount
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

export const useGetAcceleratedPayoutList = ({
  fakeData = false,
  queryVariables,
}: {
  fakeData?: boolean
  queryVariables: {
    filters: {
      marketplaceIds?: string[]
      minAmount?: number
      maxAmount?: number
      fromDate?: string
      toDate?: string
    }
    pagination: {
      pageSize: number
      pageNumber: number
    }
  }
}) => {
  const queryResult = useHeroQuery<{
    apRegulatedList: ApRegulatedItem[]
    apRegulatedListCount: number
  }>({
    gqlQuerySchema: query,
    variables: {
      marketplaceIds: queryVariables.filters.marketplaceIds,
      minAmount: queryVariables.filters.minAmount,
      maxAmount: queryVariables.filters.maxAmount,
      fromDate: queryVariables.filters.fromDate,
      toDate: queryVariables.filters.toDate,
      pageSize: queryVariables.pagination.pageSize,
      pageNumber: queryVariables.pagination.pageNumber,
    },
  })

  if (fakeData) {
    return {
      data: {
        apRegulatedList: data,
        apRegulatedListCount: data.length,
      },
      loading: false,
      error: null,
    }
  }

  return queryResult
}
