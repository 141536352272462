import { CwtApplication } from "./Application/Application.screen"
import { CwtListScreen } from "./List/List.screen"
import { GenericErrorBoundary } from "../../00_shared/components/GenericErrorBoundary"

export const creditWireTransfersRoute = {
  path: "cwt",
  children: [
    {
      index: true,
      element: <CwtListScreen />,
    },
    {
      path: "application",
      element: <CwtApplication />,
    },
  ],
  errorElement: <GenericErrorBoundary />,
}
