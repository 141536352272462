import { Navigate } from "react-router-dom"
import { GenericErrorBoundary } from "../../../00_shared/components/GenericErrorBoundary"
import { BOV3RouteProtector, UserManagementBOV3RouteProtector } from "../../../App.router"
import { TransfersRoot } from "../transfers/00_shared/components/TransfersRoot"
import { AddBeneficiary } from "../transfers/AddBeneficiary/AddBeneficiary.screen"
import { BeneficiariesRoot } from "../transfers/Beneficiaries/Beneficiaries.root"
import { Beneficiaries } from "../transfers/Beneficiaries/Beneficiaries.screen"
import { CreateTransfer } from "../transfers/CreateTransfer/CreateTransfer.screen"
import { TransferHistory } from "../transfers/TransferHistory/TransferHistory.screen"
import { TransferHistoryTransactionInformations } from "../transfers/TransferHistory/TransferHistoryTransactionInformations"
import { createTransferRoutes } from "./create-transfer.routes"

export const transfersRoutes = {
  path: "transfers",
  children: [
    {
      element: (
        <BOV3RouteProtector>
          <UserManagementBOV3RouteProtector permission="view-account">
            <TransfersRoot />
          </UserManagementBOV3RouteProtector>
        </BOV3RouteProtector>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="transactions" />,
        },
        {
          path: "transactions",
          children: [
            {
              path: "",
              element: <TransferHistory />,
              children: [{ path: ":transactionId", element: <TransferHistoryTransactionInformations /> }],
            },
          ],
        },
        {
          path: "beneficiaries",
          element: <BeneficiariesRoot />,
          children: [
            { path: "", element: <Beneficiaries /> },
            {
              path: "create",
              element: (
                <UserManagementBOV3RouteProtector permission="add-beneficiary">
                  <AddBeneficiary />
                </UserManagementBOV3RouteProtector>
              ),
            },
          ],
        },
        {
          path: "create",
          element: (
            <UserManagementBOV3RouteProtector permission="send-transfer">
              <CreateTransfer />
            </UserManagementBOV3RouteProtector>
          ),
          children: createTransferRoutes,
        },
      ],
    },
  ],
  errorElement: <GenericErrorBoundary />,
}
