import { Typography } from "@hero/krypton"
import { ComponentType, ElementType, forwardRef, ReactNode } from "react"
import styled, { DefaultTheme, PolymorphicComponentProps } from "styled-components"

type BadgeVariant = "primary" | "secondary" | "warning" | "info" | "danger" | "disabled" | "marketing"

type BadgePropsBase = {
  leftIcon?: ComponentType
  rightIcon?: ComponentType
}

type BadgePropsVariant = BadgePropsBase & {
  $variant?: BadgeVariant
}

type BadgeProps<C extends ElementType> = PolymorphicComponentProps<"web", BadgePropsVariant, C, C>

function getVariantColor(theme: DefaultTheme, $badgeVariant?: BadgeVariant) {
  switch ($badgeVariant) {
    case "primary":
      return theme.colors.primary.$300
    case "secondary":
      return theme.colors.secondary.$300
    case "warning":
      return theme.colors.warning.$300
    case "info":
      return theme.colors.info.$200
    case "danger":
      return theme.colors.danger.$300
    case "disabled":
      return theme.colors.grey.$600
    case "marketing":
      return theme.colors.white
    default:
      return theme.colors.primary.$300
  }
}

function getVariantBackgroundColor(theme: DefaultTheme, $badgeVariant?: BadgeVariant) {
  switch ($badgeVariant) {
    case "primary":
      return theme.colors.primary.$100
    case "secondary":
      return theme.colors.secondary.$100
    case "warning":
      return theme.colors.warning.$100
    case "info":
      return theme.colors.info.$100
    case "danger":
      return theme.colors.danger.$100
    case "disabled":
      return theme.colors.grey.$100
    case "marketing":
      return `linear-gradient(93.5deg, #b183f2 0%, #ffb571 50%, #9effad 100%);`
    default:
      return theme.colors.primary.$100
  }
}

const BadgeContainer = styled.div`
  display: flex;
`

const BadgeContent = styled.div.attrs<{
  $badgeVariant?: BadgeVariant
}>(({ $badgeVariant }) => ({
  $badgeVariant: $badgeVariant ?? "primary",
}))<{ $badgeVariant?: BadgeVariant }>`
  display: flex;
  align-self: self-start;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  background-color: ${({ theme, $badgeVariant }) => getVariantBackgroundColor(theme, $badgeVariant)};
  & > p {
    color: ${({ theme, $badgeVariant }) => getVariantColor(theme, $badgeVariant)};
  }
`

type BadgeType = <C extends ElementType = "div">(props: BadgeProps<C>) => ReactNode | null

export const Badge: BadgeType = forwardRef(
  <C extends ElementType = "div">(
    { children, leftIcon, rightIcon, $variant, ...rest }: BadgeProps<C>,
    ref?: React.ComponentPropsWithRef<C>["ref"],
  ) => {
    const LeftIcon: ComponentType = leftIcon ? leftIcon : () => <></>
    const RightIcon: ComponentType = rightIcon ? rightIcon : () => <></>
    return (
      <BadgeContainer {...rest} ref={ref}>
        <BadgeContent $badgeVariant={$variant}>
          <LeftIcon />
          <Typography $variant="caption-1">{children}</Typography>
          <RightIcon />
        </BadgeContent>
      </BadgeContainer>
    )
  },
)
