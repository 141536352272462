export const apRegulatedBreakpoints = {
  tablet: "80rem", // 1280px
  mobile: "64rem", // 1024px
  mobileS: "48rem", // 768px
}

export const addMonths = (date: Date, months: number) => {
  const d = new Date(date)
  d.setMonth(d.getMonth() + months)
  return d
}

export const formatDateForInput = (date: Date) => {
  return formatDateStringForInput(date.toISOString())
}

export const formatDateStringForInput = (date: string) => {
  return date.split("T")[0]
}
