import { createContext, useContext, useState, ReactNode, useCallback } from "react"
import { useEffectOnce } from "../../00_shared/hooks/useEffectOnce.hook"

interface AprContextState {
  apTable: {
    filters: {
      fromDate: string | null
      toDate: string | null
      minAmount: number | null
      maxAmount: number | null
      marketplaces: string[]
    }
    pagination: {
      currentPage: number
    }
  }
  activatedMatketplaces: string[]
}

interface PartialAprContextState {
  apTable?: {
    filters?: {
      fromDate?: string | null
      toDate?: string | null
      minAmount?: number | null
      maxAmount?: number | null
      marketplaces?: string[]
    }
    pagination?: {
      currentPage?: number
    }
  }
  activatedMatketplaces?: string[]
}

const defaultAprContextState: AprContextState = {
  apTable: {
    filters: {
      fromDate: null,
      toDate: null,
      minAmount: null,
      maxAmount: null,
      marketplaces: [],
    },
    pagination: {
      currentPage: 1,
    },
  },
  activatedMatketplaces: [],
}

const AprContext = createContext<{
  state: AprContextState
  setState: (state: PartialAprContextState) => void
  resetFilters: () => void
}>({
  state: defaultAprContextState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setState: (state: PartialAprContextState) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetFilters: () => {},
})

export const useAprContext = () => {
  return useContext<{
    state: AprContextState
    setState: (state: PartialAprContextState) => void
    resetFilters: () => void
  }>(AprContext)
}

const getStateInQueryParams = () => {
  const params = new URLSearchParams(window.location.search)

  const fromDate = params.get("fromDate")
  const toDate = params.get("toDate")
  const minAmount = params.get("minAmount")
  const maxAmount = params.get("maxAmount")

  const filters = {
    fromDate: fromDate ? fromDate : null,
    toDate: toDate ? toDate : null,
    minAmount: minAmount ? parseInt(minAmount, 10) : null,
    maxAmount: maxAmount ? parseInt(maxAmount, 10) : null,
    marketplaces: params.get("marketplaces")?.split(",") || [],
  }
  const pagination = {
    currentPage: parseInt(params.get("currentPage") || "1", 10),
  }
  return {
    apTable: {
      filters,
      pagination,
    },
  }
}

const setQueryParams = (state: AprContextState) => {
  const params = new URLSearchParams()

  if (state.apTable?.filters?.fromDate) {
    params.set("fromDate", state.apTable.filters.fromDate)
  }

  if (state.apTable?.filters?.toDate) {
    params.set("toDate", state.apTable.filters.toDate)
  }

  if (state.apTable?.filters?.minAmount) {
    params.set("minAmount", state.apTable.filters.minAmount.toString())
  }

  if (state.apTable?.filters?.maxAmount) {
    params.set("maxAmount", state.apTable.filters.maxAmount.toString())
  }

  if (state.apTable?.filters?.marketplaces?.length && state.apTable?.filters?.marketplaces?.length > 0) {
    params.set("marketplaces", state.apTable.filters.marketplaces.join(","))
  }

  if (state.apTable?.pagination?.currentPage) {
    params.set("currentPage", state.apTable.pagination.currentPage.toString())
  }

  window.history.replaceState({}, "", `${window.location.pathname}?${params}`)
}

export const AprContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<{
    apTable: {
      filters: {
        fromDate: string | null
        toDate: string | null
        minAmount: number | null
        maxAmount: number | null
        marketplaces: string[]
      }
      pagination: {
        currentPage: number
      }
    }
    activatedMatketplaces: string[]
  }>(defaultAprContextState)

  const _setState = useCallback(
    (state: AprContextState) => {
      setState(state)
    },
    [setState],
  )

  const cleanAndSetState = useCallback(
    (newProvidedState: PartialAprContextState) => {
      const newState = getNewState(state, newProvidedState)

      _setState(newState)
      setQueryParams(newState)
    },
    [_setState, state],
  )

  const resetFiltersAndSetState = useCallback(() => {
    const newState = resetFilters(state)
    _setState(newState)
    setQueryParams(newState)
  }, [_setState, state])

  useEffectOnce(() => {
    cleanAndSetState(getStateInQueryParams())
  })

  return (
    <AprContext.Provider value={{ state, setState: cleanAndSetState, resetFilters: resetFiltersAndSetState }}>
      {children}
    </AprContext.Provider>
  )
}

const getNewState = (oldState: AprContextState, newState: PartialAprContextState): AprContextState => {
  return {
    activatedMatketplaces: newState.activatedMatketplaces || oldState.activatedMatketplaces,
    apTable: {
      filters: {
        fromDate: newState.apTable?.filters?.fromDate || null,
        toDate: newState.apTable?.filters?.toDate || null,
        marketplaces: newState.apTable?.filters?.marketplaces || [],
        maxAmount: newState.apTable?.filters?.maxAmount || null,
        minAmount: newState.apTable?.filters?.minAmount || null,
      },
      pagination: {
        currentPage: newState.apTable?.pagination?.currentPage || oldState.apTable.pagination.currentPage,
      },
    },
  }
}

const resetFilters = (oldState: AprContextState): AprContextState => {
  return {
    activatedMatketplaces: oldState.activatedMatketplaces,
    apTable: {
      filters: defaultAprContextState.apTable.filters,
      pagination: {
        currentPage: 1,
      },
    },
  }
}
