import { Field, SearchIcon } from "@hero/krypton"
import { ChangeEvent } from "react"

export const SearchInput = (props: {
  placeholder: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => unknown
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <SearchIcon style={{ position: "absolute", top: "0.5rem", left: "0.5rem" }} />
      <Field style={{ paddingLeft: "2.5rem" }} type="search" {...props} />
    </div>
  )
}
