import { Navigate, Outlet } from "react-router-dom"
import { GenericErrorBoundary } from "../../00_shared/components/GenericErrorBoundary"
import { BOV3RouteProtector } from "../../App.router"
import { UserManagementScreen } from "./UserManagement.screen"

export const userManagementRoute = {
  path: "user-management",
  element: (
    <BOV3RouteProtector>
      <Outlet />
    </BOV3RouteProtector>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="members" />,
    },
    {
      path: "members",
      children: [
        {
          path: "",
          element: <UserManagementScreen />,
        },
      ],
    },
  ],
  errorElement: <GenericErrorBoundary />,
}
